import { Directive, ElementRef, HostListener, Input } from '@angular/core';
import { NgControl } from '@angular/forms';

@Directive({
  selector: '[appMaxValueInput]',
})
export class MaxValueInputDirective {
  @Input() maxValueInput: number = 99;

  constructor(
    private elRef: ElementRef,
    public model: NgControl,
  ) {}

  rectifyValue(event: any) {
    const value = Math.abs(event.target.value);
    if (value > this.maxValueInput) {
      event.target.value = this.maxValueInput;
    } else {
      event.target.value = value;
    }
    return Math.abs(event.target.value);
  }

  @HostListener('keyup', ['$event'])
  onInputChange(event: any) {
    const newValue = this.rectifyValue(event);
    this.model?.control?.setValue(newValue);
    return true;
  }

  @HostListener('change', ['$event'])
  public onScroll(event: WheelEvent) {
    const newValue = this.rectifyValue(event);
    this.model?.control?.setValue(newValue);
    return true;
  }
}
