import { Component, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'app-composite-question-dialog',
  templateUrl: './composite-question-dialog.component.html',
  styleUrls: ['./composite-question-dialog.component.scss'],
})
export class CompositeQuestionDialogComponent {
  constructor(
    public dialogRef: MatDialogRef<CompositeQuestionDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: { title: string; message: string; ctaNegative: string; ctaPositive: string },
  ) {}
}
