import { Component, EventEmitter, Input, OnChanges, Output, SimpleChanges } from '@angular/core';

@Component({
  selector: 'app-simple-list',
  templateUrl: './simple-list.component.html',
  styleUrls: ['./simple-list.component.scss'],
})
export class SimpleListComponent implements OnChanges {
  public selectedItemId: string;
  @Input() items: any[];
  @Input() preselection: unknown;
  @Output() itemSelected: EventEmitter<string> = new EventEmitter();

  constructor() {}

  getItemClass(item: any): string {
    return this.selectedItemId && item.id === this.selectedItemId ? `bg-nexus-yellow white` : 'bg-white';
  }

  selectItem(id: string) {
    this.selectedItemId = id;
    const item = this.items.find((item) => item.id === id);
    this.itemSelected.emit(item);
  }

  // OTA optimistic updates
  ngOnChanges(changes: SimpleChanges) {
    const { preselection } = changes;
    const { currentValue } = preselection;
    if (!!currentValue) {
      const index = this.items.findIndex((item) => item.id === currentValue.id);
      this.items[index] = currentValue;
    }
  }
}
