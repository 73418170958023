import _ from 'lodash-es';

import { Component, EventEmitter, Input, OnChanges, Output, SimpleChanges } from '@angular/core';

@Component({
  selector: 'app-multiselect-list',
  templateUrl: './multiselect-list.component.html',
  styleUrls: ['./multiselect-list.component.scss'],
})
export class MultiselectListComponent implements OnChanges {
  public selectedItemIds: string[] = [];
  @Input() items: unknown[];
  @Input() key: string;
  @Input() preselection: unknown[];
  @Output() itemsSelected: EventEmitter<string[]> = new EventEmitter();
  constructor() {}

  getItemClass(item: any): string {
    const itemIsSelected = this.selectedItemIds.includes(item.id);
    return itemIsSelected ? `bg-nexus-yellow white` : 'bg-white';
  }

  selectItems(id: string) {
    if (!this.preselection) {
      return;
    }
    const alreadyExists = this.selectedItemIds.includes(id);
    if (alreadyExists) {
      this.selectedItemIds = this.selectedItemIds.filter((itemId) => itemId !== id);
    } else {
      this.selectedItemIds.push(id);
    }
    this.itemsSelected.emit(this.selectedItemIds);
  }

  ngOnChanges(changes: SimpleChanges) {
    const { preselection } = changes;
    const { currentValue } = preselection;
    if (!!currentValue) {
      this.selectedItemIds = currentValue;
    }
  }
}
