import { Component, Output, OnInit, EventEmitter, Input } from '@angular/core';
import { UntypedFormControl, Validators, UntypedFormBuilder } from '@angular/forms';

import { FormBaseComponent } from 'src/app/components/form-base/form-base.component';
import { debounceTime, distinctUntilChanged, filter } from 'rxjs/operators';
import moment from 'moment';
import { DatePickerPayload } from '../interfaces/date-picker-payload';

@Component({
  selector: 'app-relative-date-chooser',
  templateUrl: './relative-date-chooser.component.html',
  styleUrls: ['./relative-date-chooser.component.scss'],
})
export class RelativeDateChooserComponent extends FormBaseComponent implements OnInit {
  @Input() initialPeriod: DatePickerPayload;
  public relativeCount: UntypedFormControl;
  public relativePeriod: UntypedFormControl;
  public relativePeriodOptions = [
    { value: 'disabled', name: 'any time' },
    { value: 'd', name: 'days ago' },
    { value: 'w', name: 'weeks ago' },
    { value: 'M', name: 'months ago' },
    { value: 'df', name: 'days from now' },
    { value: 'wf', name: 'weeks from now' },
    { value: 'Mf', name: 'months from now' },
  ];
  public disabled = false;

  constructor(private fb: UntypedFormBuilder) {
    super();
  }

  ngOnInit() {
    let initialPeriodIndex = this.relativePeriodOptions.findIndex((relativePeriodOption) => relativePeriodOption.value === this.initialPeriod.period);
    if (initialPeriodIndex === -1) {
      initialPeriodIndex = 0;
    }
    this.relativeCount = new UntypedFormControl(this.initialPeriod.value || 30, [Validators.required, Validators.min(0), Validators.max(365)]);
    this.relativePeriod = new UntypedFormControl(this.relativePeriodOptions[initialPeriodIndex].value, Validators.required);
    this.form = this.fb.group({
      relativeCount: this.relativeCount,
      relativePeriod: this.relativePeriod,
    });
    this.subscribeToForm();
  }

  subscribeToForm() {
    if (this.formDisabled) {
      return;
    }
    this.formSubscription = this.form.valueChanges
      .pipe(
        debounceTime(500),
        distinctUntilChanged(),
        filter((data) => {
          return this.form.valid;
        }),
      )
      .subscribe((formData) => {
        if (this.formDisabled) {
          return;
        }
        const { relativePeriod, relativeCount } = formData;
        // past and future
        let date;
        if (relativePeriod === 'disabled') {
          const disabledState = {
            date: undefined,
            relativePeriod,
            relativeCount,
          };
          return this.formFilled.emit(disabledState);
        }
        if (relativePeriod.indexOf('f') > -1) {
          const relativePeriodCorrected = relativePeriod.replace(/f/g, '');
          date = moment.utc().add(relativeCount, relativePeriodCorrected);
        } else {
          date = moment.utc().subtract(relativeCount, relativePeriod);
        }
        this.formFilled.emit({ date, relativePeriod, relativeCount });
      });
  }
}
