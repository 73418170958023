import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'orderByKeys',
})
export class OrderByKeysPipe implements PipeTransform {
  keyOrder = ['allDay', 'halfDay', 'oneHour', 'twoHour', 'threeHour', 'fourHour', 'fiveHour', 'sixHour', 'sevenHour', 'eightHour'];

  transform(array: { key: string; value: any }[], args?: any): any {
    if (!array) {
      return array;
    }

    const sortedArray = array.sort((a, b) => {
      const aIndex = this.keyOrder.indexOf(a.key);
      const bIndex = this.keyOrder.indexOf(b.key);

      if (aIndex > bIndex) {
        return 1;
      } else if (aIndex < bIndex) {
        return -1;
      } else {
        return 0;
      }
    });

    return sortedArray;
  }
}
