import { Component, OnInit, Input, Output, EventEmitter, ChangeDetectionStrategy } from '@angular/core';
import { Pagination } from '../../pagination/pagination.model';

@Component({
  selector: 'app-pagination-simple',
  templateUrl: './pagination-simple.component.html',
  styleUrls: ['./pagination-simple.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PaginationSimpleComponent {
  @Input() paginationState: Pagination;
  @Input() count: number;
  @Output() goToPage: EventEmitter<any> = new EventEmitter();

  constructor() {}

  get totalPagesArray(): number[] {
    const { page, hasNextPage, limit, totalPages } = this.paginationState;
    const mainArray = [];
    const pageDelimiterCeiling = Math.ceil(page / 10) * 10;
    // Show 10 from current page
    if (page <= 10) {
      for (let index = 0; index < totalPages; index++) {
        if (index < 10) {
          mainArray.push(index);
        }
        if (index === 10) {
          mainArray.push(-1);
        }
        if (index === totalPages - 1 && mainArray[mainArray.length - 1] === -1) {
          mainArray.push(index);
        }
      }
    } else {
      for (let index = pageDelimiterCeiling - 10; index < totalPages; index++) {
        if (index < pageDelimiterCeiling) {
          mainArray.push(index);
        }
        if (index === pageDelimiterCeiling) {
          mainArray.push(-1);
        }
        if (index === totalPages - 1 && mainArray[mainArray.length - 1] === -1) {
          mainArray.push(index);
        }
      }
    }
    return mainArray;
  }

  currentPageClass(page: number): string {
    if (this.paginationState.page === page) {
      return 'white bg-nexus-green current-page-active';
    } else {
      return 'black';
    }
  }

  onGoToPage(page: number): void {
    if (this.paginationState.page === page) {
      return;
    }
    this.goToPage.emit(page);
  }

  getRange() {
    const { page, limit } = this.paginationState;
    const start = (page - 1) * limit + 1;
    let end = page * limit;
    if (end > this.count) {
      end = this.count;
    }
    return `${start}-${end}`;
  }
}
