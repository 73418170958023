import { Observable } from 'rxjs';
import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { map } from 'rxjs/operators';

import { AutosaveService } from '@nexus/services/autosave.service';
import { RoleDoc } from '@nexus/models/role.model';
import { RegionModel, RegionsGroup, RegionsGroupModel, NewRegionData } from '@nexus/models/region.model';
import { PatchModel, NewPatchData } from '@nexus/models/patch.model';
import { SupplierModel, NewSupplierData, Supplier, Dcc } from '@nexus/models/supplier.model';
import { TimeSlotModel } from '@nexus/models/timeslot.model';
import { AppointmentTypeModel } from '@nexus/models/appointment-type.model';
import { SlotChangeReasonModel, ISlotChangeReasonPost, ChangeAppointmentTypePost } from '@nexus/models/slot-change-reason.model';
import { Report as AppointmentReport, ReportModel, Automation, AutomationModel, ReportUpdate } from '@nexus/models/report.model';
import {
  SurveyModel,
  QuestionModel,
  AppointmentModel,
  AppointmentPayload,
  ReservedAppointmentPayload,
  EngineersPerformance,
  DualAppointmentPayload,
  DirectAppointmentWithoutReservationPayload,
  Modes,
  AppointmentPrebook,
} from '@nexus/models/appointment.model';
import { JobTypeModel, JobType } from '@nexus/models/job-type.model';
import { SlotModel, DaySlotsGrouping, PatchSlotsGrouping } from '@nexus/models/slot.model';
import { EngineerModel, EngineerDoc } from '@nexus/models/engineer.model';
import { UserModel, UserDoc } from '@nexus/models/user.model';
import { DayJobModel, TodayFilters } from '@nexus/models/today.model';
import { SchedulingAppointmentFilters } from '@nexus/models/scheduling.model';
import { EnvironmentService, EnvironmentVariables } from '@nexus/core-services/environment.service';
import { GroupDoc } from '@nexus/models/group.model';
import { ServerResponse, ServerPaginationResponse, AtomicServerResponse, SimpleServerResponse } from '@nexus/models/api.model';
import { NonOpDay } from '@nexus/models/non-op-day.model';
import { Proxy } from '@nexus/models/proxy.model';
import { CapacityModeEnum } from '@nexus/models/tenant.model';
import { Subcontractor } from '@nexus/models/subcontractor.model';
import { TenantService } from '@nexus/auth/tenant.service';
import { Report } from '../platform/capacity/components/upload/upload';

@Injectable({
  providedIn: 'root',
})
export class ApiService {
  private apiUrl: string;
  public errorBlueprint = {
    error: '500',
    msg: 'Something went wrong',
  };

  constructor(
    private httpClient: HttpClient,
    private autosaveService: AutosaveService,
    private environmentService: EnvironmentService,
    private tenantService: TenantService,
  ) {
    this.apiUrl = `${environmentService.get(EnvironmentVariables.API_URL)}/v1/api`;
  }

  getReportingColumns(): Observable<any> {
    const completeApiUrl = `${this.apiUrl}/reporting/configs/columns`;
    return this.httpClient.get<AtomicServerResponse>(completeApiUrl).pipe(
      map((response) => {
        return response.result;
      }),
    );
  }

  getPostCodeLookupApi(postcode): Observable<any> {
    const tenantData = this.tenantService.getTenantData();
    const apiKey = `${tenantData.getAddressApiKey}`;
    const PostCodeApiUrl = 'https://api.getAddress.io/';
    const stripped = postcode.replace(/\s/g, '');
    const completeApiUrl = `${PostCodeApiUrl}/find/${stripped}?api-key=${apiKey}&expand=true`;
    return this.httpClient.get<AtomicServerResponse>(completeApiUrl).pipe(
      map((response) => {
        return response;
      }),
    );
  }

  checkIfIsNonOperationalDay(day, patch, supplier): Observable<boolean> {
    const query = `?patch=${patch}&supplier=${supplier}&day=${day}`;
    const completeApiUrl = `${this.apiUrl}/capacity/non-operational-days/verify${query}`;
    return this.httpClient.get<AtomicServerResponse>(completeApiUrl).pipe(
      map((response) => {
        return response.result;
      }),
    );
  }

  getNonOperationalDays(page: number, limit: number): Observable<any> {
    const query = `?page=${page}&limit=${limit}`;
    const completeApiUrl = `${this.apiUrl}/capacity/non-operational-days${query}`;
    return this.httpClient.get<ServerPaginationResponse>(completeApiUrl).pipe(
      map((response) => {
        return response;
      }),
    );
  }

  getPrebooks(mode: Modes, filters: any, page: number, limit: number): Observable<ServerPaginationResponse> {
    let query = `?page=${page}&limit=${limit}&mode=${mode}`;
    if (filters) {
      for (const key in filters) {
        if (key) {
          query += `&${key}=${filters[key]}`;
        }
      }
    }
    const completeApiUrl = `${this.apiUrl}/prebooks${query}`;
    return this.httpClient.get<ServerPaginationResponse>(completeApiUrl).pipe(
      map((response) => {
        return response;
      }),
    );
  }

  getProxies(): Observable<any> {
    const completeApiUrl = `${this.apiUrl}/proxies`;
    return this.httpClient.get<AtomicServerResponse>(completeApiUrl).pipe(
      map((response) => {
        return response.result;
      }),
    );
  }

  getCurrentUserAutomations(userId: string): Observable<any> {
    const completeApiUrl = `${this.apiUrl}/reporting/automation/user/${userId}`;
    return this.defaultGet(completeApiUrl);
  }

  /**
   *
   * @deprecated Do not use this class use src/app/auth/tenant.service.ts
   */
  getTenantConfigs(): Observable<any> {
    const completeApiUrl = `${this.apiUrl}/tenant`;
    return this.defaultGet(completeApiUrl);
  }

  getCoordinates(address: string, postcode: string): Observable<any> {
    const query = `?address=${address}&postcode=${postcode}`;
    const completeApiUrl = `${this.apiUrl}/geo/coordinates${query}`;
    return this.httpClient.get<AtomicServerResponse>(completeApiUrl).pipe(
      map((response) => {
        return response.result;
      }),
    );
  }

  getCoordinatesMultiple(addresses: Array<any>): Observable<any> {
    const query = `?addresses=${addresses}`;
    const completeApiUrl = `${this.apiUrl}/geo/coordinates/multiple${query}`;
    return this.httpClient.post<AtomicServerResponse>(completeApiUrl, { addresses }, { observe: 'response' }).pipe(
      map((response) => {
        return response.body;
      }),
    );
  }

  searchByPostCode(partialCode: string, supplierId: string): Observable<PatchModel[]> {
    const completeApiUrl = `${this.apiUrl}/regions/patches?postcode=${partialCode}&supplierId=${supplierId}`;
    return this.httpClient.get<ServerResponse>(completeApiUrl, { observe: 'response' }).pipe(
      map((response) => {
        this.autosaveService.finishSaving();
        const { results } = response.body;
        return results.map((result: any) => {
          return new PatchModel(result.code, result.name, result.region, result.outwardCodes, result._id);
        });
      }),
    );
  }

  getReportingCapacity(from: string, to: string, page: number, limit: number, reportingFilter: string, type: string): Observable<ServerPaginationResponse> {
    const query = `?from=${from}&to=${to}&page=${page}&limit=${limit}&reportingFilter=${reportingFilter}&type=${type}`;
    const completeApiUrl = `${this.apiUrl}/capacity/reporting/${query}`;
    return this.httpClient.get<ServerPaginationResponse>(completeApiUrl);
  }

  getReportingCapacityCount(from: string, to: string, page: number, limit: number, reportingFilter: any, type: any): Observable<any> {
    const query = `?from=${from}&to=${to}&page=${page}&limit=${limit}&reportingFilter=${reportingFilter}&type=${type}`;
    const completeApiUrl = `${this.apiUrl}/capacity/reporting/count${query}`;
    return this.httpClient.get<AtomicServerResponse>(completeApiUrl).pipe(
      map((response) => {
        const { result } = response;
        return result;
      }),
    );
  }

  getRoles(): Observable<RoleDoc[]> {
    const completeApiUrl = `${this.apiUrl}/users/roles`;
    return this.httpClient.get<ServerResponse>(completeApiUrl).pipe(
      map((response) => {
        const { results } = response;
        return results.map((result: any) => {
          return {
            id: result._id,
            name: result.name,
            isHub: result.isHub,
            locked: result.locked,
            policies: result.policies,
            hidden: result.hidden,
          };
        });
      }),
    );
  }

  getGroups(): Observable<any[]> {
    const completeApiUrl = `${this.apiUrl}/users/groups`;
    return this.httpClient.get<ServerResponse>(completeApiUrl).pipe(
      map((response) => {
        const { results } = response;
        return results.map((group: any) => {
          return {
            id: group._id,
            name: group.name,
            description: group.description,
            policies: group.policies,
          };
        });
      }),
    );
  }

  getTimeSlotsEnumeration(): Observable<any[]> {
    const completeApiUrl = `${this.apiUrl}/enums/timeslots`;
    return this.httpClient.get<ServerResponse>(completeApiUrl).pipe(
      map((response) => {
        const { results } = response;
        return results.map((timeslot: any) => {
          return new TimeSlotModel(timeslot.name, timeslot.startTime, timeslot.endTime, timeslot.duration, timeslot._id, timeslot.allDay, timeslot.pm, timeslot.am);
        });
      }),
    );
  }

  getEngineerAppointments(
    from: string,
    to: string,
    page: number,
    limit: number,
    engineer: any,
    reportingFilter?: string,
    sortingStrategy?: any,
  ): Observable<ServerPaginationResponse> {
    let query = `?from=${from}&to=${to}&page=${page}&limit=${limit}`;
    query += `&engineer=${engineer}`;
    if (reportingFilter) {
      query += `&reportingFilter=${reportingFilter}`;
    }
    if (sortingStrategy && sortingStrategy !== '') {
      query += `&sort=${sortingStrategy}`;
    }
    const completeApiUrl = `${this.apiUrl}/appointments${query}`;
    return this.httpClient.get<ServerPaginationResponse>(completeApiUrl).pipe(
      map((response) => {
        const { docs } = response;
        response.docs = docs.map((appointment: any) => {
          return new AppointmentModel(
            appointment._id,
            appointment.outwardCode,
            appointment.jobType,
            appointment.appointmentType,
            appointment.contact,
            appointment.customer,
            appointment.site,
            appointment.meterDetails,
            appointment.comments,
            appointment.createdOn,
            appointment.updatedOn,
            appointment.bookingReference,
            appointment.slot,
            appointment.engineer,
            appointment.events,
            appointment.surveys,
            appointment.status,
            appointment.deleted,
            appointment.cancellation,
            appointment.order || 0,
            null,
            null,
            null,
            null,
            null,
            null,
            null,
            null,
            null,
            null,
            null,
            appointment.secondaryEngineers,
            appointment.priority,
            null,
            null,
            null,
            appointment.travelTime,
          );
        });
        return response;
      }),
    );
  }

  getAppointmentsListCount(from: string, to: string, page: number, limit: number, filter: any, filters: any): Observable<any> {
    let query = '';
    if (from && to) {
      query += `?from=${from}&to=${to}`;
    }
    if (filter) {
      if (query === '') {
        query += `?filter=${filter}`;
      } else {
        query += `&filter=${filter}`;
      }
    }
    if (filters) {
      for (const key in filters) {
        if (key) {
          if (query === '') {
            query += `?${key}=${filters[key]}`;
          } else {
            query += `&${key}=${filters[key]}`;
          }
        }
      }
    }

    const completeApiUrl = `${this.apiUrl}/appointments/performance/count${query}`;
    return this.httpClient.get<AtomicServerResponse>(completeApiUrl).pipe(
      map((response) => {
        const { result } = response;
        return result;
      }),
    );
  }

  getCapacityLogList(from: string, to: string, page: number, limit: number, filters: any): Observable<ServerPaginationResponse> {
    let query = `?page=${page}&limit=${limit}`;
    if (from && to) {
      query += `&editedFrom=${from}&editedTo=${to}`;
    }
    if (filters) {
      for (const key in filters) {
        if (key) {
          query += `&${key}=${filters[key]}`;
        }
      }
    }

    const completeApiUrl = `${this.apiUrl}/slots/capacity-changes${query}`;
    return this.httpClient.get<ServerPaginationResponse>(completeApiUrl).pipe(
      map((response) => {
        const { docs } = response;
        response.docs = docs.map((log: any) => {
          return {
            user: log.user.name,
            date: log.day,
            region: log.region.code,
            patch: log.patch.code,
            slot: log.timeSlot.name,
            supplier: log.supplier.code,
            newValue: log.newAvailableUnits,
            oldValue: log.previousAvailableUnits,
            file: log.file,
            fileUrl: log.fileUrl,
            timestamp: log.createdOn,
            bulk: log?.bulk,
          };
        });
        return response;
      }),
    );
  }

  getAppointmentsList(from: string, to: string, page: number, limit: number, filter: any, filters: any): Observable<ServerPaginationResponse> {
    let query = `?page=${page}&limit=${limit}`;
    if (from && to) {
      query += `&from=${from}&to=${to}`;
    }
    if (filter) {
      query += `&filter=${filter}`;
    }
    if (filters) {
      for (const key in filters) {
        if (key) {
          query += `&${key}=${filters[key]}`;
        }
      }
    }

    const completeApiUrl = `${this.apiUrl}/appointments/performance${query}`;
    return this.httpClient.get<ServerPaginationResponse>(completeApiUrl).pipe(
      map((response) => {
        const { docs } = response;
        response.docs = docs.map((appointment: any) => {
          return {
            id: appointment._id,
            outwardCode: appointment.outwardCode,
            jobType: appointment.jobType,
            appointmentType: appointment.appointmentType,
            contact: appointment.contact,
            customer: appointment.customer,
            site: appointment.site,
            meterDetails: appointment.meterDetails,
            comments: appointment.comments,
            createdOn: appointment.createdOn,
            updatedOn: appointment.updatedOn,
            bookingReference: appointment.bookingReference,
            slot: appointment.slot,
            engineer: appointment.engineer,
            events: appointment.events,
            surveys: appointment.surveys,
            status: appointment.status,
            deleted: appointment.deleted,
            cancellation: appointment.cancellation, // cancellation
            order: undefined, // order
            editedBy: appointment.editedBy,
            wan: appointment.wan, // result.wan,
            wanRequestedA: undefined, // result.wanRequestedAt,
            journeysCommsTriggerPoints: undefined, // result.journeysCommsTriggerPoints,
            customerConfirmation: undefined, // result.customerConfirmation,
            eventsProcessed: appointment.eventsProcessed, // reporting
            undefined,
            dcc: appointment.dcc,
            bookingMethod: appointment.bookingMethod, // reporting
            lastEditedBy: appointment.lastEditedBy, // reporting
            failedComms: appointment?.failedComms,
            hse: appointment?.hse,
            vulnerable: appointment?.vulnerable,
            overBooked: appointment?.overBooked,
            allUserEvents: appointment?.allUserEvents,
            priority: appointment?.priority,
            debt: appointment?.debt,
            dataFlowsStatus: appointment?.dataFlowsStatus,
            nonChargeable: appointment?.nonChargeable || false,
            sip: appointment?.sip // reporting
          };
        });
        return response;
      }),
    );
  }

  getAppointments(
    from: string,
    to: string,
    page: number,
    limit: number,
    filter?: any,
    reportingFilter?: string,
    sortingStrategy?: any,
    status?: string,
    patch?: string,
    expandFilter?: string,
    filters?: SchedulingAppointmentFilters,
  ): Observable<ServerPaginationResponse> {
    let query = `?page=${page}&limit=${limit}`;
    if (from && to) {
      query += `&from=${from}&to=${to}`;
    }
    if (filter) {
      query += `&filter=${filter}`;
    }
    if (reportingFilter) {
      query += `&reportingFilter=${reportingFilter}`;
    }
    if (expandFilter) {
      query += `&expandFilter=${expandFilter}`;
    }
    if (status && status !== '') {
      query += `&status=${status}`;
    }
    if (patch && patch !== '') {
      query += `&patch=${patch}`;
    }
    if (sortingStrategy && sortingStrategy !== '') {
      query += `&sort=${sortingStrategy}`;
    }
    const filtersQuery = this.buildFiltersQuery(filters);
    const completeApiUrl = `${this.apiUrl}/appointments${query}${filtersQuery}`;
    return this.httpClient.get<ServerPaginationResponse>(completeApiUrl).pipe(
      map((response) => {
        const { docs } = response;
        response.docs = docs.map((appointment: any) => {
          return new AppointmentModel(
            appointment._id,
            appointment.outwardCode,
            appointment.jobType,
            appointment.appointmentType,
            appointment.contact,
            appointment.customer,
            appointment.site,
            appointment.meterDetails,
            appointment.comments,
            appointment.createdOn,
            appointment.updatedOn,
            appointment.bookingReference,
            appointment.slot,
            appointment.engineer,
            appointment.events,
            appointment.surveys,
            appointment.status,
            appointment.deleted,
            undefined, // cancellation
            undefined, // order
            appointment.editedBy,
            appointment.wan, // result.wan,
            undefined, // result.wanRequestedAt,
            undefined, // result.journeysCommsTriggerPoints,
            undefined, // result.customerConfirmation,
            appointment.eventsProcessed, // reporting,
            null,
            null,
            null,
            null,
            null,
            appointment.secondaryEngineers,
            appointment.priority,
          );
        });
        return response;
      }),
    );
  }

  getAppointmentsGrouped(day: string, groupBy: string, filters?: TodayFilters): Observable<any> {
    const filtersQuery = this.buildFiltersQuery(filters);
    const query = `?day=${day}&groupBy=${groupBy}${filtersQuery}`;
    const completeApiUrl = `${this.apiUrl}/appointments/status${query}`;
    return this.httpClient.get<AtomicServerResponse>(completeApiUrl).pipe(
      map((response) => {
        const { result } = response;
        const { resultCount } = response;
        return {
          groups: result,
          count: resultCount,
        };
      }),
    );
  }

  getAppointmentsGroupedRanged(from: string, to: string, groupBy: string): Observable<any> {
    const query = `?from=${from}&to=${to}&groupBy=${groupBy}`;
    const completeApiUrl = `${this.apiUrl}/appointments/status-ranged${query}`;
    return this.httpClient.get<AtomicServerResponse>(completeApiUrl).pipe(
      map((response) => {
        const { result } = response;
        const { resultCount } = response;
        return {
          groups: result,
          count: resultCount,
        };
      }),
    );
  }

  getCapacityGrouped(day: string): Observable<any> {
    const query = `?day=${day}`;
    const completeApiUrl = `${this.apiUrl}/capacity/grouped${query}`;
    return this.httpClient.get<AtomicServerResponse>(completeApiUrl).pipe(
      map((response) => {
        const { result } = response;
        const { resultCount } = response;
        return {
          groups: result,
          count: resultCount,
        };
      }),
    );
  }

  getEngineersPerformance(day: string, filters?: any): Observable<EngineersPerformance> {
    const filtersQuery = this.buildFiltersQuery(filters);
    const query = `?day=${day}${filtersQuery}`;
    const completeApiUrl = `${this.apiUrl}/appointments/performance/engineers${query}`;
    return this.httpClient.get<AtomicServerResponse>(completeApiUrl).pipe(
      map((response) => {
        const { result } = response;
        return result;
      }),
    );
  }

  getAppointmentsPerEngineer(page: number, limit: number, day: string, filters?: TodayFilters, timeline?: boolean): Observable<any> {
    const filtersQuery = this.buildFiltersQuery(filters);
    let timelineQuery = '';
    if (timeline) {
      timelineQuery += '&timeline=true';
    }
    const query = `?page=${page}&limit=${limit}&day=${day}${filtersQuery}${timelineQuery}`;
    const completeApiUrl = `${this.apiUrl}/appointments/engineer${query}`;
    return this.httpClient.get<ServerResponse>(completeApiUrl).pipe(
      map((response) => {
        const { results } = response;
        return results.map((result: any) => {
          result.appointments = result.appointments.map((appointment: any) => {
            const { slot } = appointment;
            const modeledSlot = {
              day: slot.day,
              patch: {
                id: slot.patch._id,
                code: slot.patch.code,
                name: slot.patch.name,
              },
              supplier: slot.supplier,
              timeslot: slot.timeslot,
              _id: slot._id,
              id: slot._id,
            };
            return new AppointmentModel(
              appointment._id,
              appointment.outwardCode,
              appointment.jobType,
              appointment.appointmentType,
              appointment.contact,
              appointment.customer,
              appointment.site,
              appointment.meterDetails,
              appointment.comments,
              appointment.createdOn,
              appointment.updatedOn,
              appointment.bookingReference,
              modeledSlot,
              null,
              appointment.events,
              appointment.surveys,
              appointment.status,
              appointment.deleted,
              appointment.cancellation,
              appointment.order,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              appointment.secondaryEngineers,
              appointment.priority,
              null,
              null,
              null,
              appointment.travelTime,
            );
          });
          const modeledEngineer = {
            id: result._id,
            active: result.active,
            name: result.name,
            patches: result.patches,
            homePatch: result.homePatch,
            groups: result.groups,
            qualifications: result.qualifications,
            licenses: result.licenses,
          };
          return new DayJobModel(modeledEngineer, result.appointments, result.totalAppointments);
        });
      }),
    );
  }

  getAppointmentsWeeklyPerEngineer(page: number, limit: number, startWeek: string, endWeek: string): Observable<any> {
    const query = `?page=${page}&limit=${limit}&startWeek=${startWeek}&endWeek=${endWeek}`;
    const completeApiUrl = `${this.apiUrl}/appointments/engineer/weekly${query}`;
    return this.httpClient.get<ServerResponse>(completeApiUrl).pipe(
      map((response) => {
        return response;
      }),
    );
  }

  getAppointmentsWeeklyUnassignedCount(startWeek: string, endWeek: string): Observable<any> {
    const query = `?startWeek=${startWeek}&endWeek=${endWeek}`;
    const completeApiUrl = `${this.apiUrl}/appointments/unassigned/weekly/count${query}`;
    return this.httpClient.get<ServerResponse>(completeApiUrl).pipe(
      map((response) => {
        return response;
      }),
    );
  }

  getAppointmentsWithoutEngineer(page: number, limit: number, day: string, filters?: TodayFilters): Observable<ServerPaginationResponse> {
    const filtersQuery = this.buildFiltersQuery(filters);
    const query = `?page=${page}&limit=${limit}&day=${day}${filtersQuery}`;
    const completeApiUrl = `${this.apiUrl}/appointments/unassigned${query}`;
    return this.httpClient.get<ServerPaginationResponse>(completeApiUrl).pipe(
      map((response) => {
        const { docs } = response;
        response.docs = docs.map((appointment: any) => {
          const { slot } = appointment;
          const modeledSlot = {
            day: slot.day,
            patch: {
              id: slot.patch._id,
              code: slot.patch.code,
              name: slot.patch.name,
            },
            supplier: slot.supplier,
            timeslot: slot.timeslot,
            _id: slot._id,
            id: slot._id,
          };
          return new AppointmentModel(
            appointment._id,
            appointment.outwardCode,
            appointment.jobType,
            appointment.appointmentType,
            appointment.contact,
            appointment.customer,
            appointment.site,
            appointment.meterDetails,
            appointment.comments,
            appointment.createdOn,
            appointment.updatedOn,
            appointment.bookingReference,
            modeledSlot,
            appointment.engineer,
            appointment.events,
            appointment.surveys,
            appointment.status,
            appointment.deleted,
            appointment.cancellation,
            appointment.order || 0,
            null,
            null,
            null,
            null,
            null,
            null,
            null,
            null,
            null,
            null,
            null,
            appointment.secondaryEngineers,
            appointment.priority,
          );
        });
        return response;
      }),
    );
  }

  getUser(userId: string, query?: any): Observable<any> {
    const completeApiUrl = `${this.apiUrl}/users/${userId}/`;
    return this.httpClient.get<AtomicServerResponse>(completeApiUrl).pipe(
      map((response) => {
        return response.result;
      }),
    );
  }

  getUsers(page: number, limit: number, engineersOnly: boolean, query?: any): Observable<ServerPaginationResponse> {
    const suffix = engineersOnly ? '&engineersOnly=true' : '&usersOnly=true';
    let queryString = `?page=${page}&limit=${limit}${suffix}`;
    if (query) {
      const queryKeys = Object.keys(query);
      queryKeys.forEach((queryKey, i) => {
        queryString += `&${queryKey}=${query[queryKey]}`;
      });
    }
    const completeApiUrl = `${this.apiUrl}/users${queryString}`;
    return this.httpClient.get<ServerPaginationResponse>(completeApiUrl).pipe(
      map((response) => {
        const { docs } = response;
        response.docs = docs.map((user: any) => {
          if (engineersOnly) {
            return new EngineerModel(
              user.name,
              user.email,
              user.patches || [],
              user._id,
              undefined,
              user.homePatch,
              user.groups || [],
              user.subcontractors,
              user.qualifications || [],
              user.licenses || [],
            );
          } else {
            return new UserModel(user.name, user.email, user.roles, user._id, user.groups, user.hidden, user.active, user.superUser, user.subcontractors);
          }
        });
        return response;
      }),
    );
  }

  getEngineerAppointmentsCount(page: number, limit: number, query?: any): Observable<ServerPaginationResponse> {
    let queryString = `?page=${page}&limit=${limit}&engineersOnly=true`;
    if (query) {
      const queryKeys = Object.keys(query);
      queryKeys.forEach((queryKey, i) => {
        queryString += `&${queryKey}=${query[queryKeys[i]]}`;
      });
    }
    const completeApiUrl = `${this.apiUrl}/appointments/engineer/count${queryString}`;
    return this.httpClient.get<ServerPaginationResponse>(completeApiUrl).pipe(
      map((response) => {
        const { docs } = response;
        response.docs = docs.map((engineer: any) => {
          return new EngineerModel(
            engineer.name,
            engineer.email,
            engineer.patches,
            engineer._id,
            engineer.appointmentsCount,
            engineer.homePatch,
            engineer.groups,
            engineer.subcontractor,
            engineer.qualifications,
            engineer.licenses,
            engineer.active
          );
        });
        return response;
      }),
    );
  }

  // TODO: Refactor this method, we're no longer in support of creating Object instances
  getPrebook(id: string): Observable<AppointmentPrebook> {
    const completeApiUrl = `${this.apiUrl}/prebooks/${id}`;
    return this.httpClient.get<AtomicServerResponse>(completeApiUrl, { observe: 'response' }).pipe(
      map((response) => {
        const { result } = response.body;
        const { customer, site, meterDetails, createdOn, supplier, updatedOn, contact, patch, postcode, _id, debt, attachments, charges, mode, sip } = result;
        const { jobType, appointmentType } = result;
        const normalizedJobType = new JobTypeModel(jobType.name, jobType.code, jobType.fuelType, jobType._id);
        const normalizedAppointmentType = new AppointmentTypeModel(
          appointmentType.name,
          appointmentType.code,
          appointmentType.jobTypes,
          appointmentType._id,
          appointmentType.emergency,
        );

        const prebook: AppointmentPrebook = {
          id: _id,
          outwardCode: postcode,
          jobType: normalizedJobType,
          appointmentType: normalizedAppointmentType,
          contact: contact,
          customer: customer,
          site: site,
          meterDetails: meterDetails,
          createdOn: createdOn,
          updatedOn: updatedOn,
          bookingReference: 'PREBOOK',
          slot: { supplier: supplier, patch: patch },
          events: result.events || [],
          status: result.status,
          deleted: result.deleted,
          editedBy: result.editedBy,
          attachments: attachments,
          debt: debt,
          charges: charges,
          mode: mode,
          sip
        };
        return prebook;
      }),
    );
  }

  getAppointment(id: string): Observable<AppointmentModel> {
    const completeApiUrl = `${this.apiUrl}/appointments/${id}`;
    return this.httpClient.get<AtomicServerResponse>(completeApiUrl, { observe: 'response' }).pipe(
      map((response) => {
        const { result } = response.body;
        const { customer } = result;
        let normalizedSurveys: SurveyModel[] = [];
        const surveys: any[] = result.surveys || [];
        if (surveys.length > 0) {
          normalizedSurveys = surveys.map((survey) => {
            const normalizedQuestions = survey.questions.map((question) => {
              return new QuestionModel(
                question.id,
                question.type,
                question.answer,
                question.key,
                question.question,
                question.deleted,
                question.photoUrl,
                question.modifyDate,
              );
            });
            return new SurveyModel(survey.code, survey.sequence, survey.rootId, survey.groupId, survey.groupName, survey.name, survey.id, normalizedQuestions);
          });
        }
        const { slot } = result;
        const normalizedSlot = {
          id: slot._id,
          day: slot.day,
          patch: new PatchModel(slot.patch.code, slot.patch.name, slot.patch.region, slot.patch.outwardCodes, slot.patch._id),
          supplier: new SupplierModel(
            slot.supplier.crm,
            slot.supplier.name,
            slot.supplier.code,
            slot.supplier.abbreviation,
            slot.supplier.patches,
            slot.supplier.timeSlots,
            slot.supplier._id,
            slot.supplier.logo,
            slot.supplier.dccs,
          ),
          timeslot: slot.timeslot,
          timeSlot: slot.timeslot,
        };

        const { jobType, appointmentType } = result;
        const normalizedJobType = jobType;
        normalizedJobType.id = jobType._id;
        const normalizedAppointmentType = new AppointmentTypeModel(
          appointmentType.name,
          appointmentType.code,
          appointmentType.jobTypes,
          appointmentType._id,
          appointmentType.emergency,
          appointmentType.meta,
        );

        return new AppointmentModel(
          result._id,
          result.outwardCode,
          normalizedJobType,
          normalizedAppointmentType,
          result.contact,
          customer,
          result.site,
          result.meterDetails,
          result.comments,
          result.createdOn,
          result.updatedOn,
          result.bookingReference,
          normalizedSlot,
          result.engineer,
          result.events || [],
          normalizedSurveys,
          result.status,
          result.deleted,
          result.cancellation,
          result.order,
          result.editedBy,
          result.wan,
          result.wanRequestedAt,
          result.journeysCommsTriggerPoints,
          result.customerConfirmation,
          null,
          result.journeyComms || null,
          result.dcc,
          null,
          result.dataFlows || null,
          result.overBooked || null,
          result.secondaryEngineers || null,
          result.priority || null,
          result.s2RequestStatus || null,
          result.metaData || null,
          result.attachments || [],
          result.travelTime || null,
          result.debt || null,
          result.charges || null,
          result.convertedFrom || null,
          null, // mode
          result.dataFlowsStatus || null,
          result.sip || null,
          result.nonChargeable || false,
        );
      }),
    );
  }

  getSlotChangeReasons(jobTypeCode?: string): Observable<SlotChangeReasonModel[]> {
    const queryParam = jobTypeCode ? `?jobType=${jobTypeCode}` : '';
    const completeApiUrl = `${this.apiUrl}/enums/slot-change-reasons${queryParam}`;
    return this.httpClient.get<ServerResponse>(completeApiUrl).pipe(
      map((response) => {
        return response.results;
      }),
    );
  }

  getAppointmentTypeChangeReasons(): Observable<any[]> {
    const completeApiUrl = `${this.apiUrl}/enums/appointment-type-change-reasons`;
    return this.httpClient.get<ServerResponse>(completeApiUrl).pipe(
      map((response) => {
        return response.results;
      }),
    );
  }

  getAppointmentTypesEnumeration(qp?: string): Observable<any[]> {
    const qpStr = qp ? `?${qp}=true` : '';
    const completeApiUrl = `${this.apiUrl}/enums/appointment-types${qpStr}`;
    return this.httpClient.get<ServerResponse>(completeApiUrl).pipe(
      map((response) => {
        const { results } = response;
        return results.map((appointmentType: any) => {
          const { jobTypes, name, code, _id, emergency, meta, hh, optionalBookingProps, hiddenBookingProps } = appointmentType;
          return new AppointmentTypeModel(name, code, jobTypes, _id, emergency, meta, hh, optionalBookingProps, hiddenBookingProps);
        });
      }),
    );
  }

  getSubcontractors(): Observable<string[]> {
    const completeApiUrl = `${this.apiUrl}/enums/subcontractors`;
    return this.httpClient.get<ServerResponse>(completeApiUrl).pipe(
      map((response) => {
        return response.results;
      }),
    );
  }

  getJobTypesEnumeration(qp?: string): Observable<JobType[]> {
    const qpStr = qp ? `?${qp}=true` : '';
    const completeApiUrl = `${this.apiUrl}/enums/job-types${qpStr}`;
    return this.httpClient.get<ServerResponse>(completeApiUrl).pipe(
      map((response) => {
        const { results } = response;
        return results.map((jobType: any) => {
          return {
            ...jobType,
            id: jobType._id,
          };
        });
      }),
    );
  }

  getRegions(removeZeroPatch?: boolean): Observable<RegionModel[]> {
    const query = removeZeroPatch ? `?removeZeroPatch=true` : `?removeZeroPatch=false`;
    const completeApiUrl = `${this.apiUrl}/regions${query}`;
    return this.httpClient.get<ServerResponse>(completeApiUrl).pipe(
      map((response) => {
        const { results } = response;
        return results.map((region: any) => {
          return new RegionModel(region._id, region.code);
        });
      }),
    );
  }

  getDccs(): Observable<Dcc[]> {
    const completeApiUrl = `${this.apiUrl}/enums/dccs`;
    return this.httpClient.get<ServerResponse>(completeApiUrl).pipe(
      map((response) => {
        const { results } = response;
        return results;
      }),
    );
  }

  getRegionsWithPatches(hasAppointments?: boolean, day?: string, onlyWithPatch?: boolean): Observable<RegionsGroup[]> {
    const hasAppointmentsQuery = hasAppointments ? `&hasAppointments=true` : '';
    const dayQuery = day ? `&day=${day}` : '';
    const onlyQuery = onlyWithPatch ? `&onlywithpatch=${onlyWithPatch}` : '';
    const query = `?includePatches=true${hasAppointmentsQuery}${dayQuery}${onlyQuery}`;
    const completeApiUrl = `${this.apiUrl}/regions${query}`;
    return this.httpClient.get<ServerResponse>(completeApiUrl).pipe(
      map((response) => {
        const { results } = response;
        return results.map((regionGroup: RegionsGroup) => {
          const { patches, region } = regionGroup;
          const patchesModels = patches.map((patch) => {
            return new PatchModel(patch.code, patch.name, patch.region, patch.outwardCodes, patch._id, patch.hasUnassignedAppointments);
          });
          return new RegionsGroupModel(new RegionModel(region._id, region.code), patchesModels);
        });
      }),
    );
  }

  getPatches(patch?: string): Observable<PatchModel[]> {
    const query = patch ? `?patch=${patch}` : '';
    const completeApiUrl = `${this.apiUrl}/regions/patches${query}`;
    return this.httpClient.get<ServerResponse>(completeApiUrl).pipe(
      map((response) => {
        const { results } = response;
        return results.map((serverPatch: any) => {
          return new PatchModel(serverPatch.code, serverPatch.name, serverPatch.region, serverPatch.outwardCodes, serverPatch._id);
        });
      }),
    );
  }

  getSlots(supplierId: string, day: string): Observable<PatchSlotsGrouping[]> {
    let query = supplierId ? `?supplier=${supplierId}` : '';
    if (supplierId) {
      query += `&day=${day}`;
    } else {
      query += `?day=${day}`;
    }
    const completeApiUrl = `${this.apiUrl}/slots${query}`;
    return this.httpClient.get<ServerResponse>(completeApiUrl).pipe(
      map((response) => {
        const { results } = response;
        return results.map((slotGroup: any) => {
          const { slots } = slotGroup;
          const mappedSlots = slots.map((slot) => {
            return new SlotModel(slot.availableUnits, slot.patch, slot.supplier, slot.timeSlot, slot.day, slot._id, slot.nonOpDay, slot.appointments);
          });
          return new PatchSlotsGrouping(mappedSlots, slotGroup.patch);
        });
      }),
    );
  }

  getSlotsByWeekPatchAndSupplier(
    supplierId: string,
    patchId: string,
    from: string,
    to: string,
    type?: 'compatible' | 'emergency',
    durationDays?: number,
  ): Observable<DaySlotsGrouping[]> {
    const typeQuery = type ? `&type=${type}` : '';
    const query = `?supplier=${supplierId}&patch=${patchId}&from=${from}&to=${to}${typeQuery}&durationDays=${durationDays}`;
    const completeApiUrl = `${this.apiUrl}/slots/capacity${query}`;
    return this.httpClient.get<ServerResponse>(completeApiUrl).pipe(
      map((response) => {
        const { results } = response;
        return results.map((slotGroup: any) => {
          const { slots } = slotGroup;
          const mappedSlots = slots.map((slot) => {
            const { timeSlot } = slot;
            const timeSlotModel = new TimeSlotModel(timeSlot.name, timeSlot.startTime, timeSlot.endTime, timeSlot.duration, timeSlot._id, timeSlot.allDay, timeSlot.pm, timeSlot.am);
            return new SlotModel(
              slot.availableUnits,
              slot.patch,
              slot.supplier,
              timeSlotModel, // can be a string
              slot.day,
              slot._id,
              null,
              null,
              durationDays ? slot.multidaySupportOnly : null,
            );
          });
          return new DaySlotsGrouping(mappedSlots, slotGroup.day);
        });
      }),
    );
  }

  performCustomerLookup(supplierId: string, accountNumber: string): Observable<AppointmentModel[]> {
    const completeApiUrl = `${this.apiUrl}/customers/lookup?supplier=${supplierId}&accountNumber=${accountNumber}`;
    return this.httpClient.get<ServerResponse>(completeApiUrl).pipe(
      map((response) => {
        const { results } = response;
        return results.map((appointment: any) => {
          // Patch
          let patchModel: PatchModel;
          const { patch } = appointment.slot;
          patchModel = new PatchModel(patch.code, patch.name, patch.region, patch.outwardCodes, patch._id);
          // Normalize Appointment
          return new AppointmentModel(
            undefined,
            appointment.site.postCode,
            appointment.meterDetails?.jobType, // not available when no meter points
            undefined,
            appointment.contact,
            appointment.customer,
            appointment.site,
            appointment.meterDetails,
            undefined,
            undefined,
            undefined,
            undefined,
            { patch: patchModel },
            undefined,
            undefined,
            undefined,
            undefined,
          );
        });
      }),
    );
  }

  getSupplierPatchesTree(): Observable<any[]> {
    const completeApiUrl = `${this.apiUrl}/slots/overview`;
    return this.httpClient.get<ServerResponse>(completeApiUrl).pipe(
      map((response) => {
        const { results } = response;
        return results.map((slotGroup: any) => {
          return slotGroup;
        });
      }),
    );
  }

  updatePatchesRegions(regionsAndPatches: RegionsGroup[]): Observable<boolean> {
    this.autosaveService.startSaving();
    const completeApiUrl = `${this.apiUrl}/regions`;
    return this.httpClient.put<SimpleServerResponse>(completeApiUrl, regionsAndPatches).pipe(
      map((response) => {
        const { status } = response;
        this.autosaveService.finishSaving();
        return status;
      }),
    );
  }

  updateSupplier(supplier: SupplierModel, fields: string[]): Observable<boolean> {
    this.autosaveService.startSaving();
    const completeApiUrl = `${this.apiUrl}/suppliers/${supplier.id}`;
    const dataToUpdate = {};
    fields.forEach((prop) => {
      dataToUpdate[prop] = supplier[prop];
    });
    return this.httpClient.put<SimpleServerResponse>(completeApiUrl, dataToUpdate).pipe(
      map((response) => {
        const { status } = response;
        this.autosaveService.finishSaving();
        return status;
      }),
    );
  }

  createNewRegion(region: NewRegionData): Observable<RegionsGroup> {
    this.autosaveService.startSaving();
    const completeApiUrl = `${this.apiUrl}/regions/`;
    return this.httpClient
      .post<AtomicServerResponse>(completeApiUrl, region, {
        observe: 'response',
      })
      .pipe(
        map((response) => {
          this.autosaveService.finishSaving();
          const { result } = response.body;
          return new RegionsGroupModel(result.region, result.patches);
        }),
      );
  }

  createRole(role: RoleDoc): Observable<RoleDoc> {
    this.autosaveService.startSaving();
    const completeApiUrl = `${this.apiUrl}/users/roles`;
    return this.httpClient
      .post<AtomicServerResponse>(completeApiUrl, role, {
        observe: 'response',
      })
      .pipe(
        map((response) => {
          this.autosaveService.finishSaving();
          const { result } = response.body;
          return {
            id: result._id,
            name: result.name,
            policies: result.policies,
          };
        }),
      );
  }

  createGroup(group: GroupDoc): Observable<GroupDoc> {
    this.autosaveService.startSaving();
    const completeApiUrl = `${this.apiUrl}/users/groups`;
    return this.httpClient
      .post<AtomicServerResponse>(completeApiUrl, group, {
        observe: 'response',
      })
      .pipe(
        map((response) => {
          this.autosaveService.finishSaving();
          const { result } = response.body;
          return {
            id: result._id,
            name: result.name,
            description: result.description,
          };
        }),
      );
  }

  createSubcontractor(subcontractor: any): Observable<any> {
    this.autosaveService.startSaving();
    const completeApiUrl = `${this.apiUrl}/users/subcontractors`;
    return this.httpClient
      .post<AtomicServerResponse>(completeApiUrl, subcontractor, {
        observe: 'response',
      })
      .pipe(
        map((response) => {
          this.autosaveService.finishSaving();
          const { result } = response.body;
          return result;
        }),
      );
  }

  updateSubcontractor(subcontractor: Subcontractor, id: string): Observable<string> {
    this.autosaveService.startSaving();
    const completeApiUrl = `${this.apiUrl}/users/subcontractors/${id}`;
    return this.httpClient
      .put<AtomicServerResponse>(completeApiUrl, subcontractor, {
        observe: 'response',
      })
      .pipe(
        map((response) => {
          this.autosaveService.finishSaving();
          const { result } = response.body;
          return result;
        }),
      );
  }

  createNewPatch(patch: NewPatchData): Observable<PatchModel> {
    this.autosaveService.startSaving();
    const completeApiUrl = `${this.apiUrl}/regions/patches`;
    return this.httpClient.post<AtomicServerResponse>(completeApiUrl, { patch }, { observe: 'response' }).pipe(
      map((response) => {
        this.autosaveService.finishSaving();
        const { result } = response.body;
        const patchModel = new PatchModel(result.code, result.name, result.region, result.outwardCodes, result._id);
        return patchModel;
      }),
    );
  }

  createCapacityConfig(capacityConfig: any): Observable<any> {
    this.autosaveService.startSaving();
    const completeApiUrl = `${this.apiUrl}/tenant/capacity-configs`;
    return this.httpClient.post<ServerResponse>(completeApiUrl, capacityConfig).pipe(
      map((response) => {
        this.autosaveService.finishSaving();
        return response;
      }),
    );
  }

  getCapacityConfigs(): Observable<any[]> {
    const completeApiUrl = `${this.apiUrl}/tenant/capacity-configs`;
    return this.httpClient.get<any>(completeApiUrl).pipe(
      map((response) => {
        const { result } = response;
        return result;
      }),
    );
  }

  deleteCapacityConfig(id: string): Observable<any> {
    const completeApiUrl = `${this.apiUrl}/tenant/capacity-configs/${id}/`;
    return this.defaultDelete(completeApiUrl);
  }

  updateCapacityConfig(id: string, payload: any): Observable<any[]> {
    this.autosaveService.startSaving();
    const completeApiUrl = `${this.apiUrl}/tenant/capacity-configs/${id}`;
    return this.defaultUpdate(completeApiUrl, payload);
  }

  updateConfig(id: string, payload: any): Observable<any[]> {
    this.autosaveService.startSaving();
    const completeApiUrl = `${this.apiUrl}/tenant/${id}`;
    return this.defaultUpdate(completeApiUrl, payload);
  }

  createSlots(batch: SlotModel[], capacityMode?: CapacityModeEnum): Observable<any> {
    this.autosaveService.startSaving();
    let mode = (capacityMode && capacityMode === CapacityModeEnum.FLEXIBLE) ? '?flexible=true' : '';
    const completeApiUrl = `${this.apiUrl}/slots${mode}`;
    return this.httpClient.post<ServerResponse>(completeApiUrl, { batch }).pipe(
      map((response) => {
        this.autosaveService.finishSaving();
        const { results } = response;
        return results.map((slot: any) => {
          return new SlotModel(slot.availableUnits, slot.patch, slot.supplier, slot.timeSlot, slot.day, slot._id);
        });
      }),
    );
  }

  createSlotsFromFile(textData: string, type: string, supplier: string): Observable<Report> {
    this.autosaveService.startSaving();
    const completeApiUrl = `${this.apiUrl}/slots/upload`;
    return this.httpClient.post<AtomicServerResponse>(completeApiUrl, { textData, type, supplier }).pipe(
      map((response) => {
        this.autosaveService.finishSaving();
        const { result } = response;
        return result;
      }),
    );
  }

  createPrebooksFromFile(mode: Modes, textData: string): Observable<any> {
    this.autosaveService.startSaving();
    const completeApiUrl = `${this.apiUrl}/prebooks/bua?mode=${mode}`;
    return this.httpClient.post<AtomicServerResponse>(completeApiUrl, { textData, type: 'text/csv' }).pipe(
      map((response) => {
        this.autosaveService.finishSaving();
        const { result } = response;
        return result;
      }),
    );
  }

  updatePatches(patches: PatchModel[]): Observable<any> {
    this.autosaveService.startSaving();
    const completeApiUrl = `${this.apiUrl}/regions/patches`;
    return this.httpClient
      .put<[any]>(completeApiUrl, patches, {
        observe: 'response',
      })
      .pipe(
        map((items) => {
          this.autosaveService.finishSaving();
          return items;
        }),
      );
  }

  updateRegion(region: RegionModel): Observable<any> {
    this.autosaveService.startSaving();
    const completeApiUrl = `${this.apiUrl}/regions/${region.id}/`;
    return this.defaultUpdate(completeApiUrl, region);
  }

  updateProxy(id: string, stateObj: any): Observable<any> {
    this.autosaveService.startSaving();
    const completeApiUrl = `${this.apiUrl}/proxies/${id}/`;
    return this.defaultUpdate(completeApiUrl, stateObj);
  }

  updateEngineer(engineer: EngineerDoc): Observable<any> {
    this.autosaveService.startSaving();
    const completeApiUrl = `${this.apiUrl}/users/${engineer.id}/`;
    return this.defaultUpdate(completeApiUrl, engineer);
  }

  patchEngineer(engineerId: string, payload: Partial<EngineerDoc>): Observable<any> {
    this.autosaveService.startSaving();
    const completeApiUrl = `${this.apiUrl}/users/${engineerId}`;
    return this.defaultPatch(completeApiUrl, payload);
  }

  updateUser(user: UserDoc): Observable<any> {
    this.autosaveService.startSaving();
    const completeApiUrl = `${this.apiUrl}/users/${user.id}/`;
    return this.httpClient
      .put<AtomicServerResponse>(completeApiUrl, user, {
        observe: 'response',
      })
      .pipe(
        map((response) => {
          this.autosaveService.finishSaving();
          const { result } = response.body;
          return new UserModel(
            result.name,
            result.email,
            result.roles,
            result._id,
            result.groups,
            result.hidden,
            result.active,
            result.superUser,
            result.subcontractors,
          );
        }),
      );
  }

  updateRole(role: RoleDoc): Observable<any> {
    this.autosaveService.startSaving();
    const completeApiUrl = `${this.apiUrl}/users/roles/${role.id}/`;
    return this.httpClient
      .put<AtomicServerResponse>(completeApiUrl, role, {
        observe: 'response',
      })
      .pipe(
        map((response) => {
          this.autosaveService.finishSaving();
          const { result } = response.body;
          return {
            id: result._id,
            name: result.name,
            policies: result.policies,
          };
        }),
      );
  }

  updateGroup(group: GroupDoc): Observable<any> {
    this.autosaveService.startSaving();
    const completeApiUrl = `${this.apiUrl}/users/groups/${group.id}/`;
    return this.httpClient
      .put<AtomicServerResponse>(completeApiUrl, group, {
        observe: 'response',
      })
      .pipe(
        map((response) => {
          this.autosaveService.finishSaving();
          const { result } = response.body;
          return {
            id: result._id,
            name: result.name,
            description: result.description,
          };
        }),
      );
  }

  deletePatch(id: string): Observable<any> {
    const completeApiUrl = `${this.apiUrl}/regions/patches/${id}/`;
    return this.defaultDelete(completeApiUrl);
  }

  deleteReport(id: string): Observable<any> {
    const completeApiUrl = `${this.apiUrl}/reporting/${id}/`;
    return this.defaultDelete(completeApiUrl);
  }

  deleteRegion(id: string): Observable<any> {
    const completeApiUrl = `${this.apiUrl}/regions/${id}/`;
    return this.defaultDelete(completeApiUrl);
  }

  deleteSupplier(id: string): Observable<any> {
    const completeApiUrl = `${this.apiUrl}/suppliers/${id}/`;
    return this.defaultDelete(completeApiUrl);
  }

  deleteProxy(id: string): Observable<any> {
    const completeApiUrl = `${this.apiUrl}/proxies/${id}/`;
    return this.defaultDelete(completeApiUrl);
  }

  deleteRole(id: string): Observable<any> {
    const completeApiUrl = `${this.apiUrl}/users/roles/${id}/`;
    return this.defaultDelete(completeApiUrl);
  }

  deleteGroup(id: string): Observable<any> {
    const completeApiUrl = `${this.apiUrl}/users/groups/${id}/`;
    return this.defaultDelete(completeApiUrl);
  }

  deleteSubcontractor(id: string): Observable<any> {
    const completeApiUrl = `${this.apiUrl}/users/subcontractors/${id}/`;
    return this.defaultDelete(completeApiUrl);
  }

  getSuppliers(context?: string): Observable<any[]> {
    const qp = context ? `?context=${context}` : '';
    const completeApiUrl = `${this.apiUrl}/suppliers${qp}`;
    return this.httpClient.get<ServerResponse>(completeApiUrl).pipe(
      map((response) => {
        const { results } = response;
        return results.map((supplier: Supplier) => {
          return {
            crm: supplier.crm,
            name: supplier.name,
            code: supplier.code,
            active: supplier.active,
            abbreviation: supplier.abbreviation,
            patches: supplier.patches,
            timeSlots: supplier.timeSlots,
            appointmentTypes: supplier.appointmentTypes,
            jobTypes: supplier.jobTypes,
            id: supplier._id,
            capacityCaps: supplier.capacityCaps,
            subcontractors: supplier.subcontractors,
          };
        });
      }),
    );
  }

  getSupplier(id?: string): Observable<any> {
    const completeApiUrl = `${this.apiUrl}/suppliers/${id}`;
    return this.defaultGet(completeApiUrl);
  }

  createSupplier(supplier: NewSupplierData): Observable<SupplierModel> {
    this.autosaveService.startSaving();
    const completeApiUrl = `${this.apiUrl}/suppliers`;
    return this.httpClient.post<AtomicServerResponse>(completeApiUrl, supplier, { observe: 'response' }).pipe(
      map((response) => {
        this.autosaveService.finishSaving();
        const { result } = response.body;
        const patchModel = new SupplierModel(result.crm, result.name, result.code, result.abbreviation, result.patches, result.timeSlots, result._id);
        return patchModel;
      }),
    );
  }

  defaultDelete(completeApiUrl: string): Observable<any> {
    this.autosaveService.startSaving();
    return this.httpClient.delete<[any]>(completeApiUrl, { observe: 'response' }).pipe(
      map((item) => {
        this.autosaveService.finishSaving();
        return item;
      }),
    );
  }

  defaultUpdate(completeApiUrl: string, data: any, header?: string): Observable<any> {
    this.autosaveService.startSaving();
    const headers = header ? new HttpHeaders().set(header, 'true') : undefined;
    return this.httpClient.put<any>(completeApiUrl, data, { headers, observe: 'response' }).pipe(
      map((response) => {
        this.autosaveService.finishSaving();
        const { result, results } = response.body as any;
        if (result && result._id) {
          result.id = result._id;
        }
        return result ? result : results;
      }),
    );
  }

  defaultPatch(completeApiUrl: string, data: any): Observable<any> {
    this.autosaveService.startSaving();
    return this.httpClient.patch<AtomicServerResponse>(completeApiUrl, data, { observe: 'response' }).pipe(
      map((response) => {
        this.autosaveService.finishSaving();
        const { result, results } = response.body as any;
        return result ? result : results;
      }),
    );
  }

  defaultCreate(completeApiUrl: string, data: any): Observable<any> {
    this.autosaveService.startSaving();
    return this.httpClient.post<AtomicServerResponse>(completeApiUrl, data, { observe: 'response' }).pipe(
      map((response) => {
        this.autosaveService.finishSaving();
        const { result, results } = response.body as any;
        return result ? result : results;
      }),
    );
  }

  defaultGet(completeApiUrl: string): Observable<any> {
    this.autosaveService.startSaving();
    return this.httpClient.get<AtomicServerResponse>(completeApiUrl).pipe(
      map((response) => {
        this.autosaveService.finishSaving();
        return response.result;
      }),
    );
  }

  reserveSlot(slotId: string, outwardCode: string, appointmentTypeId: string, jobTypeId: string): Observable<AppointmentModel> {
    this.autosaveService.startSaving();
    const payload = {
      outwardCode,
      slotId,
      appointmentTypeId,
      jobTypeId,
    };
    const completeApiUrl = `${this.apiUrl}/appointments`;
    return this.httpClient
      .post<AtomicServerResponse>(completeApiUrl, payload, {
        observe: 'response',
      })
      .pipe(
        map((response) => {
          this.autosaveService.finishSaving();
          const { result } = response.body;
          return new AppointmentModel(
            result._id,
            result.outwardCode,
            result.jobType,
            result.appointmentType,
            undefined, // contactDetails
            undefined, // customerDetails
            undefined, // siteDetails
            undefined, // meterDetails
            undefined, // comments
            result.createdOn,
            result.updatedOn,
            undefined,
          );
        }),
      );
  }

  bookDirectDualAppointment(dualAppointmentPayload: DualAppointmentPayload): Observable<AppointmentModel[]> {
    this.autosaveService.startSaving();
    const completeApiUrl = `${this.apiUrl}/appointments/dual`;
    return this.httpClient
      .post<any>(completeApiUrl, dualAppointmentPayload, {
        observe: 'response',
      })
      .pipe(
        map((response) => {
          this.autosaveService.finishSaving();
          const { results } = response.body;
          return results.map((result) => {
            return new AppointmentModel(
              result._id,
              result.outwardCode,
              result.jobType,
              result.appointmentType,
              result.contactDetails, // contactDetails
              result.customerDetails, // customerDetails
              result.siteDetails, // siteDetails
              result.meterDetails, // meterDetails
              result.comments, // comments
              result.createdOn,
              result.updatedOn,
              result.bookingReference, // bookingReference
            );
          });
        }),
      );
  }

  bookDirectAppointmentWithoutReservation(appointmentPayload: DirectAppointmentWithoutReservationPayload): Observable<AppointmentModel> {
    this.autosaveService.startSaving();
    const completeApiUrl = `${this.apiUrl}/appointments`;
    return this.httpClient
      .post<AtomicServerResponse>(completeApiUrl, appointmentPayload, {
        observe: 'response',
      })
      .pipe(
        map((response) => {
          this.autosaveService.finishSaving();
          const { result } = response.body;
          return new AppointmentModel(
            result._id,
            result.outwardCode,
            result.jobType,
            result.appointmentType,
            result.contactDetails, // contactDetails
            result.customerDetails, // customerDetails
            result.siteDetails, // siteDetails
            result.meterDetails, // meterDetails
            result.comments, // comments
            result.createdOn,
            result.updatedOn,
            result.bookingReference, // bookingReference
          );
        }),
      );
  }

  bookAppointment(appointmentPayload: ReservedAppointmentPayload, appointmentId?: string): Observable<AppointmentModel> {
    this.autosaveService.startSaving();
    const completeApiUrl = `${this.apiUrl}/appointments/${appointmentId}`;
    return this.httpClient
      .put<AtomicServerResponse>(completeApiUrl, appointmentPayload, {
        observe: 'response',
      })
      .pipe(
        map((response) => {
          this.autosaveService.finishSaving();
          const { result } = response.body;
          return new AppointmentModel(
            result._id,
            result.outwardCode,
            result.jobType,
            result.appointmentType,
            result.contactDetails, // contactDetails
            result.customerDetails, // customerDetails
            result.siteDetails, // siteDetails
            result.meterDetails, // meterDetails
            result.comments, // comments
            result.createdOn,
            result.updatedOn,
            result.bookingReference,
            undefined, // slot
            result.engineer,
          );
        }),
      );
  }

  // Used to make a Direct appoinment without a prior Slot selected
  bookDirectAppointment(appointmentPayload: AppointmentPayload): Observable<AppointmentModel> {
    this.autosaveService.startSaving();
    const completeApiUrl = `${this.apiUrl}/appointments`;
    return this.httpClient
      .post<AtomicServerResponse>(completeApiUrl, appointmentPayload, {
        observe: 'response',
      })
      .pipe(
        map((response) => {
          this.autosaveService.finishSaving();
          const { result } = response.body;
          return new AppointmentModel(
            result._id,
            result.outwardCode,
            result.jobType,
            result.appointmentType,
            result.contactDetails, // contactDetails
            result.customerDetails, // customerDetails
            result.siteDetails, // siteDetails
            result.meterDetails, // meterDetails
            result.comments, // comments
            result.createdOn,
            result.updatedOn,
            result.bookingReference, // bookingReference
          );
        }),
      );
  }

  swapAppointment(slot: string, appointment: string, changeReasons: ISlotChangeReasonPost): Observable<any> {
    this.autosaveService.startSaving();
    const completeApiUrl = `${this.apiUrl}/appointments/swap`;
    return this.httpClient.post<AtomicServerResponse>(completeApiUrl, { slot, appointment, changeReasons }, { observe: 'response' }).pipe(
      map((response) => {
        this.autosaveService.finishSaving();
        const { result } = response.body;
        return result;
      }),
    );
  }

  createReportAutomation(payload: Automation): Observable<any> {
    this.autosaveService.startSaving();
    const completeApiUrl = `${this.apiUrl}/reporting/automation/`;
    return this.defaultCreate(completeApiUrl, payload);
  }

  createWanRequest(appointmentId: string): Observable<any> {
    this.autosaveService.startSaving();
    const completeApiUrl = `${this.apiUrl}/appointments/${appointmentId}/wan/`;
    return this.defaultCreate(completeApiUrl, {});
  }

  createProxy(proxy: Proxy): Observable<any> {
    this.autosaveService.startSaving();
    const completeApiUrl = `${this.apiUrl}/proxies`;
    return this.defaultCreate(completeApiUrl, proxy);
  }

  getPDFBinary(appointmentId: string): Observable<any> {
    const completeApiUrl = `${this.apiUrl}/appointments/${appointmentId}/pdf/`;
    let headers = new HttpHeaders();
    headers = headers.set('Accept', 'application/pdf');
    return this.httpClient.get(completeApiUrl, { headers, responseType: 'blob' });
  }

  getReportAutomation(report: string): Observable<any> {
    const completeApiUrl = `${this.apiUrl}/reporting/automation/report/${report}`;
    return this.httpClient.get<AtomicServerResponse>(completeApiUrl).pipe(
      map((response) => {
        const { result } = response;
        return new AutomationModel(result._id, result.report, result.frequency, result.enabled);
      }),
    );
  }

  updateReportAutomation(id: string, payload: Automation): Observable<any> {
    this.autosaveService.startSaving();
    const completeApiUrl = `${this.apiUrl}/reporting/automation/${id}`;
    return this.defaultUpdate(completeApiUrl, payload);
  }

  createReport(payload: AppointmentReport): Observable<any> {
    this.autosaveService.startSaving();
    const completeApiUrl = `${this.apiUrl}/reporting/`;
    return this.defaultCreate(completeApiUrl, payload);
  }

  updateReport(id: string, report: ReportUpdate): Observable<any> {
    this.autosaveService.startSaving();
    const completeApiUrl = `${this.apiUrl}/reporting/${id}`;
    return this.defaultUpdate(completeApiUrl, report);
  }

  getReports(page: number, limit: number, filters?: any): Observable<ServerPaginationResponse> {
    let query = `?page=${page}&limit=${limit}`;
    let filtersQuery = '';
    if (filters) {
      const queryKeys = Object.keys(filters);
      queryKeys.forEach((queryKey) => {
        filtersQuery += `&${queryKey}=${filters[queryKey]}`;
      });
      query += `${filtersQuery}`;
    }
    const completeApiUrl = `${this.apiUrl}/reporting${query}`;
    return this.httpClient.get<ServerPaginationResponse>(completeApiUrl).pipe(
      map((response) => {
        const { docs } = response;
        response.docs = docs.map((report: any) => {
          return new ReportModel(
            report._id,
            report.name,
            report.visibility,
            report.filters,
            report.columns,
            report.timeWindow,
            report.user,
            report.queryBuilderSqlFilters,
          );
        });
        return response;
      }),
    );
  }

  updateAppointment(appointmentPayload: AppointmentPayload, appointmentId: string, header?: string): Observable<AppointmentModel> {
    this.autosaveService.startSaving();
    const completeApiUrl = `${this.apiUrl}/appointments/${appointmentId}/`;
    return this.defaultUpdate(completeApiUrl, appointmentPayload, header);
  }

  deleteReservation(id: string): Observable<any> {
    const completeApiUrl = `${this.apiUrl}/appointments/${id}/`;
    return this.defaultDelete(completeApiUrl);
  }

  deleteNonOperationalDay(id: string): Observable<any> {
    const completeApiUrl = `${this.apiUrl}/capacity/non-operational-days/${id}/`;
    return this.defaultDelete(completeApiUrl);
  }

  setAppointmentEvent(payload: any, id: string): Observable<AppointmentModel> {
    this.autosaveService.startSaving();
    const completeApiUrl = `${this.apiUrl}/appointments/${id}/events/`;
    return this.defaultCreate(completeApiUrl, payload);
  }

  changeInFlight(payload: { timestamp: Date; change: ChangeAppointmentTypePost }, id: string): Observable<AppointmentModel> {
    this.autosaveService.startSaving();
    const completeApiUrl = `${this.apiUrl}/appointments/${id}/change-in-flight/`;
    return this.defaultCreate(completeApiUrl, payload);
  }

  addAppointmentTextEvent(payload: any, id: string): Observable<AppointmentModel> {
    this.autosaveService.startSaving();
    const completeApiUrl = `${this.apiUrl}/appointments/${id}/logs`;
    return this.defaultCreate(completeApiUrl, payload);
  }

  createAppointmentComment(id: string, payload: any): Observable<any[]> {
    this.autosaveService.startSaving();
    const completeApiUrl = `${this.apiUrl}/appointments/${id}/comments`;
    return this.defaultCreate(completeApiUrl, payload);
  }

  updateAppointmentComment(id: string, commentId: string, payload: any): Observable<any[]> {
    this.autosaveService.startSaving();
    const completeApiUrl = `${this.apiUrl}/appointments/${id}/comments/${commentId}`;
    return this.defaultUpdate(completeApiUrl, payload);
  }

  deleteAppointmentComment(appointmentId: string, commentId: string): Observable<any> {
    const completeApiUrl = `${this.apiUrl}/appointments/${appointmentId}/comments/${commentId}/`;
    return this.defaultDelete(completeApiUrl);
  }

  createNewNonOperationalDay(payload: NonOpDay): Observable<NonOpDay> {
    this.autosaveService.startSaving();
    const completeApiUrl = `${this.apiUrl}/capacity/non-operational-days`;
    return this.defaultCreate(completeApiUrl, payload);
  }

  buildFiltersQuery(filters: any): string {
    let queryString = '';
    if (filters) {
      const queryKeys = Object.keys(filters);
      queryKeys.forEach((queryKey, i) => {
        if (!!filters[queryKeys[i]]) {
          queryString += `&${queryKeys[i]}=${filters[queryKeys[i]]}`;
        }
      });
    }
    return queryString;
  }
}
