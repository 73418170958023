import { AbstractControl, AsyncValidator, NG_ASYNC_VALIDATORS, ValidationErrors } from '@angular/forms';
import { Directive, Input } from '@angular/core';
import { catchError, debounceTime, distinctUntilChanged, filter, first, map, switchMap, tap } from 'rxjs/operators';
import { Observable, of } from 'rxjs';

import { ValidationService } from '@nexus/core-services/validation.service';

@Directive({
  selector: '[appOutwardCodeValidator][ngModel],[appOutwardCodeValidator][formControl],[appOutwardCodeValidator][formControlName]',
  providers: [
    {
      provide: NG_ASYNC_VALIDATORS,
      useExisting: OutwardCodeDirective,
      multi: true
    }
  ]
})
export class OutwardCodeDirective implements AsyncValidator {
  constructor(private validationService: ValidationService) {}

  validate(control: AbstractControl): Observable<ValidationErrors | null> {
    const value = control.value;

    if (!value || value.trim() === '') {
      return of(null);
    }

    return this.validationService.validateIfOutwardCodeIsUnique(value).pipe(
      debounceTime(250),
      map(response => {
        if (value.length < 2) {
          return null; // Return null directly if length is less than 2
        }
        if (response === 'Duplicated Outward Code') {
          return { duplicateError: response };  // Return the error object directly
        }
        return null;
      }),
      first()
    );
  }
}