import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'postcode',
})
export class PostcodePipe implements PipeTransform {
  transform(value: any, args?: any): any {
    if (value) {
      const withoutSpaces = value.replace(/\s/g, '');
      const parts = withoutSpaces.match(/.{1,3}/g);
      return parts.length > 0 ? `${parts[0]} ${parts[1]}` : value;
    } else {
      return value;
    }
  }
}
