import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'truncate',
})
export class TruncatePipe implements PipeTransform {
  transform(value: any, maxLength: number): any {
    if (maxLength) {
      return value.substring(0, maxLength);
    }
    return value.substring(0, 12);
  }
}
