import { Directive, ElementRef, HostListener, Input } from '@angular/core';

@Directive({
  selector: '[appFinancialNumberFormat]',
})
export class FinancialNumberFormatDirective {
  @Input() appFinancialNumberFormat: number;

  private el: HTMLInputElement;

  constructor(private elementRef: ElementRef) {
    this.el = this.elementRef.nativeElement;
  }

  parseFinancialNumber(value: string) {
    if (value.trim() === '') {
      return;
    }
    // if value contains other than numbers or symbols set to 0
    if (!value.match(/^[0-9.,]+$/)) {
      this.el.value = '0,00';
      return;
    }

    const appFinancialNumberFormat = parseFloat(value.replace(/,/g, ''));
    let formattedValue = Number(appFinancialNumberFormat.toFixed(2)).toLocaleString('en-GB', { minimumFractionDigits: 2 });
    if (!formattedValue.includes('.')) {
      formattedValue += ',00';
    }
    this.el.value = formattedValue;
  }

  @HostListener('focus', ['$event.target.value'])
  onFocus(value: string) {
    this.parseFinancialNumber(value);
  }

  @HostListener('blur', ['$event.target.value'])
  onBlur(value: string) {
    this.parseFinancialNumber(value);
  }
}
