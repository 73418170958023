<div class="tc mw8 center" *ngIf="paginationState">
  <div class="dib overflow-hidden">
    <nav class="cf" data-name="pagination-numbers-bordered">
      <a
        class="fl dib link black bn f6 f6-ns b pagination-rounded"
        [ngClass]="{ disabled: !paginationState.hasPrevPage }"
        href="javascript:;"
        (click)="onGoToPage(1)"
      >
        &#8676;
      </a>
      <a
        class="fl dib link black bn f6 f6-ns b pagination-rounded"
        [ngClass]="{ disabled: !paginationState.hasPrevPage }"
        href="javascript:;"
        (click)="paginationState.hasPrevPage && onGoToPage(paginationState.page - 1)"
      >
        &#8592;
      </a>
      <a class="fr dib link black bn f6 f6-ns b pagination-rounded disabled" href="javascript:;"> &#8677; </a>
      <a
        class="fr dib link black bn f6 f6-ns b pagination-rounded"
        [ngClass]="{ disabled: !paginationState.hasNextPage }"
        href="javascript:;"
        (click)="paginationState.hasNextPage && onGoToPage(paginationState.page + 1)"
      >
        &#8594;
      </a>
      <div class="overflow-hidden center dt f4 tc">...</div>
    </nav>
  </div>
</div>
<div class="tc" *ngIf="count && count > -1">Showing {{ getRange() }} of {{ count }} appointments</div>
