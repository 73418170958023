import { Directive, Input } from '@angular/core';
import { AbstractControl, NG_ASYNC_VALIDATORS, ValidationErrors } from '@angular/forms';
import { ValidationService } from '@nexus/core-services/validation.service';
import { of } from 'rxjs';
import { Observable } from 'rxjs';
import { debounceTime, distinctUntilChanged, first, map, switchMap } from 'rxjs/operators';

@Directive({
  selector: '[appUkMobileNumberValidator], [FormControl]',
  providers: [{ provide: NG_ASYNC_VALIDATORS, useExisting: UkMobileNumberDirective, multi: true }],
})
export class UkMobileNumberDirective {
  @Input() originalValue?: string;

  constructor(private validationService: ValidationService) {}

  validate(control: AbstractControl): Observable<ValidationErrors | null> {
    const { value } = control;
    if (!control.valueChanges || !value || control.pristine || this.originalValue === value) {
      return of(null);
    }
    return control.valueChanges
      .pipe(
        debounceTime(250),
        distinctUntilChanged(),
        switchMap((valueChg) => this.validationService.verifyIfNumberIsUkMobile(value)),
        map((x) => {
          if (typeof x === 'string') {
            return { invalidError: x };
          }
          return !x ? { invalidError: 'Invalid UK Mobile Number' } : null;
        }),
      )
      .pipe(first());
  }
}
