import { Directive, Output, EventEmitter, HostListener, Renderer2, ElementRef } from '@angular/core';

@Directive({
  selector: '[appScrollTracker]',
})
export class ScrollTrackerDirective {
  @Output() scrollingFinished = new EventEmitter<void>();

  emitted = false;

  constructor(
    private el: ElementRef,
    private renderer: Renderer2,
  ) {}

  @HostListener('scroll')
  onScroll(e: Event): void {
    const scrollHeight = this.el.nativeElement.scrollHeight;
    const scrolled = this.el.nativeElement.offsetHeight + this.el.nativeElement.scrollTop;
    if (scrolled >= scrollHeight && !this.emitted) {
      this.emitted = true;
      this.scrollingFinished.emit();
    } else if (scrolled < scrollHeight) {
      this.emitted = false;
    }
  }
}
