<h1 mat-dialog-title [innerHTML]="data.title | breakOn"></h1>
<div mat-dialog-content>
  <p>{{ data.message }}</p>
</div>
<div mat-dialog-actions>
  <div class="tr w-100">
    <button class="nexus-button nexus-button-red outlined" [mat-dialog-close]="false">{{ data.ctaNegative }}</button>
    <button class="nexus-button nexus-button-primary ml2" [mat-dialog-close]="true">{{ data.ctaPositive }}</button>
  </div>
</div>
