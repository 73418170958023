import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DragDropModule } from '@angular/cdk/drag-drop';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { Routes, RouterModule } from '@angular/router';
import { provideHttpClient, withInterceptorsFromDi } from '@angular/common/http';
import { ScrollingModule } from '@angular/cdk/scrolling';
import { MomentDateAdapter, MatMomentDateModule } from '@angular/material-moment-adapter';
import { DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE } from '@angular/material/core';
import { MatButtonModule } from '@angular/material/button';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MAT_RIPPLE_GLOBAL_OPTIONS, MatOptionModule, MatNativeDateModule } from '@angular/material/core';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatDialogModule } from '@angular/material/dialog';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatInputModule } from '@angular/material/input';
import { MatSelectModule } from '@angular/material/select';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatSnackBarModule } from '@angular/material/snack-bar';

import { WidgetsModule } from '../widgets/widgets.module';
import { SpinnerComponent } from './spinner/spinner.component';
import { NotificationComponent } from './notification/notification.component';
import { PaginationComponent } from './pagination/pagination.component';
import { PostcodePipe } from './pipes/postcode.pipe';
import { FilterPipe } from './pipes/filter.pipe';
import { OrderByKeysPipe } from './pipes/keys-order-by.pipe';
import { ImageGhostDirective } from './directives/imageghost.directive';
import { PageHeaderComponent } from './page-header/page-header.component';
import { RelativeDateChooserComponent } from './relative-date-chooser/relative-date-chooser.component';
import { QuestionDialogComponent } from './components/question-dialog/question-dialog.component';
import { CompositeQuestionDialogComponent } from './components/composite-question-dialog/composite-question-dialog.component';
import { PaginationSimpleComponent } from './components/pagination-simple/pagination-simple.component';
import { DotSpinnerComponent } from './components/dot-spinner/dot-spinner.component';
import { AutocompleteDirective } from './directives/autocomplete.directive';
import { DebounceClickDirective } from './directives/debounceClick.directive';
import { ToggleButtonComponent } from './components/toggle-button/toggle-button.component';
import { TruncatePipe } from './pipes/truncate.pipe';
import { ScrollTrackerDirective } from './directives/scroll-tracker.directive';
import { DisableAfterClickDirective } from './directives/disable-after-click.directive';
import { AllowWordsOnlyDirective } from './directives/allow-words-only.directive';
import { MpxnDuplicatesValidatorDirective } from './directives/mpxn-duplicates-validator.directive';
import { DisableButtonsReadOnlyAuthorizationDirective } from './directives/disable-buttons-read-only-authorization.directive';
import { SwitchComponent } from './components/switch/switch.component';
import { PopperDirective } from './directives/popper.directive';
import { BreakOnPipe } from './pipes/break-on.pipe';
import { UkMobileNumberDirective } from './directives/uk-mobile-number.directive';
import { UkPhoneNumberDirective } from './directives/uk-phone-number.directive';
import { ImageviewerDirective } from './directives/imageviewer.directive';
import { SimpleListComponent } from './components/simple-list/simple-list.component';
import { MultiselectListComponent } from './components/multiselect-list/multiselect-list.component';
import { DragDropFilesDirective } from './directives/drag-drop-files.directive';
import { MaxValueInputDirective } from './directives/max-value-input.directive';
import { SscValidatorDirective } from './directives/ssc-validator.directive';
import { ProfileClassValidatorDirective } from './directives/profile-class-validator.directive';
import { FinancialNumberFormatDirective } from './directives/financial-number-format.directive';
import { OutwardCodeDirective } from './directives/outward-code.directive';

@NgModule({ declarations: [
        SpinnerComponent,
        NotificationComponent,
        PaginationComponent,
        PostcodePipe,
        FilterPipe,
        OrderByKeysPipe,
        ImageGhostDirective,
        PageHeaderComponent,
        RelativeDateChooserComponent,
        QuestionDialogComponent,
        CompositeQuestionDialogComponent,
        PaginationSimpleComponent,
        DotSpinnerComponent,
        AutocompleteDirective,
        DebounceClickDirective,
        ToggleButtonComponent,
        TruncatePipe,
        BreakOnPipe,
        ScrollTrackerDirective,
        DisableAfterClickDirective,
        AllowWordsOnlyDirective,
        MpxnDuplicatesValidatorDirective,
        DisableButtonsReadOnlyAuthorizationDirective,
        SwitchComponent,
        PopperDirective,
        UkMobileNumberDirective,
        UkPhoneNumberDirective,
        ImageviewerDirective,
        SimpleListComponent,
        MultiselectListComponent,
        DragDropFilesDirective,
        MaxValueInputDirective,
        SscValidatorDirective,
        ProfileClassValidatorDirective,
        FinancialNumberFormatDirective,
        OutwardCodeDirective,
        // CtrlKeysDirective,
    ],
    exports: [
        CommonModule,
        WidgetsModule,
        MatOptionModule,
        MatDialogModule,
        ScrollingModule,
        ScrollingModule,
        DragDropModule,
        MatSelectModule,
        FormsModule,
        ReactiveFormsModule,
        MatTooltipModule,
        MatButtonModule,
        MatCheckboxModule,
        MatDatepickerModule,
        MatFormFieldModule,
        MatAutocompleteModule,
        MatNativeDateModule,
        MatInputModule,
        SpinnerComponent,
        NotificationComponent,
        PaginationComponent,
        PostcodePipe,
        FilterPipe,
        ImageGhostDirective,
        PageHeaderComponent,
        RelativeDateChooserComponent,
        QuestionDialogComponent,
        CompositeQuestionDialogComponent,
        OrderByKeysPipe,
        PaginationSimpleComponent,
        DotSpinnerComponent,
        AutocompleteDirective,
        DebounceClickDirective,
        ToggleButtonComponent,
        TruncatePipe,
        BreakOnPipe,
        ScrollTrackerDirective,
        DisableAfterClickDirective,
        AllowWordsOnlyDirective,
        MpxnDuplicatesValidatorDirective,
        DisableButtonsReadOnlyAuthorizationDirective,
        SwitchComponent,
        PopperDirective,
        ImageviewerDirective,
        UkMobileNumberDirective,
        UkPhoneNumberDirective,
        SimpleListComponent,
        MultiselectListComponent,
        DragDropFilesDirective,
        MaxValueInputDirective,
        ProfileClassValidatorDirective,
        SscValidatorDirective,
        FinancialNumberFormatDirective,
        OutwardCodeDirective
    ], imports: [RouterModule,
        CommonModule,
        MatTooltipModule,
        MatSelectModule,
        ReactiveFormsModule,
        MatMomentDateModule,
        MatDialogModule,
        DragDropModule,
        ScrollingModule,
        ScrollingModule,
        MatOptionModule,
        FormsModule,
        MatButtonModule,
        MatCheckboxModule,
        MatDatepickerModule,
        MatFormFieldModule,
        MatAutocompleteModule,
        MatNativeDateModule,
        MatSnackBarModule,
        MatInputModule], providers: [
        { provide: MAT_RIPPLE_GLOBAL_OPTIONS, useValue: { float: 'always' } },
        {
            provide: DateAdapter,
            useClass: MomentDateAdapter,
            deps: [MAT_DATE_LOCALE],
        },
        provideHttpClient(withInterceptorsFromDi()),
    ] })
export class SharedModule {}
