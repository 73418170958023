<form [formGroup]="form">
  <div class="flex flex-column">
    <div class="w-100">
      <label for="query" class="f6 db fw6 mb2">Relative Period<span class="normal black-60"></span></label>
    </div>
    <div class="w-100 flex">
      <input
        class="input-reset ba b--black-20 pa2 mb2 db w-30 mr2"
        [ngClass]="relativePeriod.value === 'disabled' ? 'disabled' : ''"
        type="number"
        min="0"
        max="365"
        placeholder="duration"
        formControlName="relativeCount"
      />
      <select class="input-reset ba b--black-20 pa2 mb2 db w-70" formControlName="relativePeriod">
        <option *ngFor="let option of relativePeriodOptions" [ngValue]="option.value">
          {{ option.name }}
        </option>
      </select>
    </div>
  </div>
</form>
