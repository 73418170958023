import { Directive, ElementRef, Input, OnDestroy, OnInit, Renderer2 } from '@angular/core';
import Popper, { Placement, PopperOptions } from 'popper.js';
import { fromEvent, merge, Subject } from 'rxjs';
import { filter, pluck, takeUntil } from 'rxjs/operators';

@Directive({
  selector: '[appPopper]',
})
export class PopperDirective implements OnInit, OnDestroy {
  @Input() target: HTMLElement;
  @Input() delay?: number;
  @Input() placement?: Placement;
  @Input() appPopper?: any;
  public timeoutRef;
  private popper: Popper;
  private readonly defaultConfig: PopperOptions = {
    placement: 'top',
    removeOnDestroy: true,
    positionFixed: false,
    modifiers: {
      arrow: {
        element: '.popper__arrow',
      },
    },
    eventsEnabled: false,
  };
  private readonly destroy$ = new Subject<void>();

  constructor(
    private readonly el: ElementRef,
    private readonly renderer: Renderer2,
  ) {}

  ngOnInit(): void {
    // Initialize popper only if mouse over
    const reference = this.appPopper ? this.appPopper : this.el.nativeElement;
    this.renderer.setStyle(this.target, 'display', 'none');
    merge(fromEvent(reference, 'mouseenter'), fromEvent(reference, 'mouseleave'))
      .pipe(
        filter(() => this.popper !== null),
        pluck('type'),
        takeUntil(this.destroy$),
      )
      .subscribe((e: any) => this.mouseHoverHandler(e));
  }

  ngOnDestroy(): void {
    if (!this.popper) {
      return;
    }
    this.popper.destroy();
    this.destroy$.next();
    this.destroy$.complete();
    if (this.timeoutRef) {
      clearTimeout(this.timeoutRef);
    }
  }

  private mouseHoverHandler(e: any): void {
    const delay = this.delay ?? 100;
    if (e === 'mouseenter') {
      // initialize popper only on mouseenter
      const reference = this.appPopper ? this.appPopper : this.el.nativeElement;
      this.popper = new Popper(reference, this.target, {
        ...this.defaultConfig,
        placement: this.placement || this.defaultConfig.placement,
      });
      // show popper after delay
      if (this.delay) {
        this.timeoutRef = setTimeout(() => {
          this.renderer.removeStyle(this.target, 'display');
          this.popper.enableEventListeners();
          this.popper.scheduleUpdate();
        }, delay);
      } else {
        this.renderer.removeStyle(this.target, 'display');
        this.popper.enableEventListeners();
        this.popper.scheduleUpdate();
      }
    } else {
      if (this.timeoutRef) {
        clearTimeout(this.timeoutRef);
      }
      this.renderer.setStyle(this.target, 'display', 'none');
      this.popper.disableEventListeners();
    }
  }
}
