import { Directive, Input } from '@angular/core';
import { AbstractControl, NG_ASYNC_VALIDATORS, ValidationErrors } from '@angular/forms';
import { ValidationService } from '@nexus/core-services/validation.service';
import { of } from 'rxjs';
import { Observable } from 'rxjs';
import { distinctUntilChanged, first, map, switchMap } from 'rxjs/operators';

@Directive({
  selector: '[appMpxnDuplicatesValidator], [FormControl]',
  providers: [{ provide: NG_ASYNC_VALIDATORS, useExisting: MpxnDuplicatesValidatorDirective, multi: true }],
})
export class MpxnDuplicatesValidatorDirective {
  @Input() originalValue?: string;
  @Input() crmBooking?: string;
  @Input() jobType?: any;
  @Input() appointmentType?: any;

  constructor(private validationService: ValidationService) {}

  validate(control: AbstractControl): Observable<ValidationErrors | null> {
    const hasCrmByPass = this.crmBooking === 'true';
    let errorLabel = 'MPAN';
    const { value } = control;
    // Specific for CRM set
    if (!control.valueChanges || !value || (control.pristine && !hasCrmByPass) || (this.originalValue === value && !hasCrmByPass)) {
      return of(null);
    }
    let checkPayload;
    // eslint-disable-next-line
    if (control.parent.controls['mpan']) {
      checkPayload = { mpan: value };
    } else {
      errorLabel = 'MPRN';
      checkPayload = { mprn: value };
    }

    return control.valueChanges
      .pipe(
        distinctUntilChanged(),
        switchMap((valueChg) => this.validationService.verifyIfMpxnIsValid(checkPayload, this.jobType, this.appointmentType)),
        map((x) => {
          if (typeof x === 'string') {
            return { duplicateError: x };
          }
          return !x ? { duplicateError: `An active appointment with this ${errorLabel} already exists` } : null;
        }),
      )
      .pipe(first());
  }
}
