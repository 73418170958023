import { AbstractControl, NG_ASYNC_VALIDATORS, ValidationErrors } from '@angular/forms';
import { Directive, Input } from '@angular/core';
import { debounceTime, distinctUntilChanged, filter, first, map, switchMap } from 'rxjs/operators';
import { Observable } from 'rxjs';
import { of } from 'rxjs';

import { ValidationService } from '@nexus/core-services/validation.service';

@Directive({
  selector: '[appProfileClassValidator], [FormControl]',
  providers: [{ provide: NG_ASYNC_VALIDATORS, useExisting: ProfileClassValidatorDirective, multi: true }],
})
export class ProfileClassValidatorDirective {
  constructor(private validationService: ValidationService) {}
  validate(control: AbstractControl): Observable<ValidationErrors> {
    const value = control.value;
    let checkPayload = { profileClass: value };

    // When the control's value is empty, no need to proceed with the async validation.
    if (!value || value?.trim() === '') {
      return of(null);
    }

    // Proceed with the async validation.
    return this.validationService.verifyIfProfileClassIsValid(checkPayload).pipe(
      filter(() => value.length >= 2),
      debounceTime(250),
      distinctUntilChanged(),
      map((x) => {
        if (typeof x === 'string') {
          return { duplicateError: x };
        }
        return !x ? { duplicateError: 'Invalid Profile Class' } : null;
      }),
    );
  }
}
