<h1 mat-dialog-title [innerHTML]="data | breakOn"></h1>

<div mat-dialog-actions>
  <div class="tr w-100">
    <ng-container *ngIf="data.indexOf('Check Meter Removal') > -1; else second">
      <button class="nexus-button nexus-button-red outlined" [mat-dialog-close]="false">Cancel</button>
      <button class="nexus-button nexus-button-primary ml2" [mat-dialog-close]="true">Continue</button>
    </ng-container>
    <ng-template #second>
      <ng-container *ngIf="data.indexOf('Track & Trace') > -1; else third">
        <button class="nexus-button nexus-button-red outlined" [mat-dialog-close]="false">Skip</button>
        <button class="nexus-button nexus-button-primary ml2" [mat-dialog-close]="true">Add</button>
      </ng-container>
    </ng-template>
    <ng-template #third>
      <ng-container *ngIf="data.indexOf('Capacity') > -1; else forth">
        <button class="nexus-button nexus-button-red outlined" [mat-dialog-close]="false">Cancel</button>
        <button class="nexus-button nexus-button-primary ml2" [mat-dialog-close]="true">Save</button>
      </ng-container>
    </ng-template>
    <ng-template #forth>
      <button class="nexus-button nexus-button-red outlined" [mat-dialog-close]="false">No</button>
      <button class="nexus-button nexus-button-primary ml2" [mat-dialog-close]="true">Yes</button>
    </ng-template>
  </div>
</div>
