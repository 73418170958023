import { Directive, OnDestroy, Input, EventEmitter, HostListener, Renderer2, ElementRef, OnInit, Output } from '@angular/core';
import { of } from 'rxjs';
import { Observable, Subscription } from 'rxjs';
import { delay, tap } from 'rxjs/operators';

@Directive({
  selector: 'a[appDisableAfterClick]',
})
export class DisableAfterClickDirective implements OnDestroy {
  subscription: Subscription;
  @Output() clickEvt = new EventEmitter();

  @HostListener('click', ['$event'])
  onClick(evt: any) {
    if (this.el.nativeElement.className.indexOf('disabled') > -1) {
      evt.target.disabled = true;
      evt.preventDefault();
      evt.stopPropagation();
      return false;
    }
    this.clickEvt.emit(evt);
    this.subscription = of(true)
      .pipe(
        delay(10),
        tap((val) => {
          this.renderer.addClass(this.el.nativeElement, 'disabled');
        }),
      )
      .pipe(delay(5000))
      .subscribe((timedItem) => {
        this.renderer.removeClass(this.el.nativeElement, 'disabled');
      });
  }

  constructor(
    private renderer: Renderer2,
    private el: ElementRef,
  ) {}

  ngOnDestroy() {
    if (this.subscription) {
      this.subscription.unsubscribe();
    }
  }
}
