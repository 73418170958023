import { Component, OnInit, Input, EventEmitter, Output } from '@angular/core';

@Component({
  selector: 'app-toggle-button',
  templateUrl: './toggle-button.component.html',
  styleUrls: ['./toggle-button.component.scss'],
})
export class ToggleButtonComponent {
  @Output() toggleChanged: EventEmitter<any> = new EventEmitter();
  @Input() toggleState = false;
  constructor() {}

  onToggleSwitch() {
    this.toggleState = !this.toggleState;
    this.toggleChanged.emit(this.toggleState);
  }
}
