import { CustomerDetails, CustomerContactDetails } from '@nexus/models/customer.model';
import { MetersData } from '@nexus/models/meter.model';
import { UserDoc } from '@nexus/models/user.model';
import { AppointmentType } from '@nexus/models/appointment-type.model';
import { JobType } from '@nexus/models/job-type.model';
import { Payload } from './enigma.model';

const debtJourneySteps = [
  'Awaiting HR letter',
  'Court Booking made',
  'HR letter sent',
  'Warrant of entry granted',
  'Warrant partners booked',
  'Supplier Go Decision',
];

enum AppointmentStatusEnum {
  'COMPLETED' = 'COMPLETED',
  'STARTED' = 'STARTED',
  'ON_SITE' = 'ON_SITE',
  'ON_ROUTE' = 'ON_ROUTE',
  'ABORTED' = 'ABORTED',
  'BOOKED' = 'BOOKED',
  'CANCELLED' = 'CANCELLED',
  'PAUSED' = 'PAUSED',
}

enum AppointmentStatusPretty {
  'COMPLETED' = 'Completed',
  'STARTED' = 'Started',
  'ON_SITE' = 'On Site',
  'ON_ROUTE' = 'On Route',
  'ABORTED' = 'Aborted',
  'BOOKED' = 'Booked',
  'CANCELLED' = 'Cancelled',
  'PAUSED' = 'Paused',
  'IN_PROGRESS' = 'In Progress',
  'RESCHEDULED' = 'Rescheduled',
}

enum EventTypesEnum {
  'JOURNEY' = 'JOURNEY',
  'BOOKING' = 'BOOKING',
}

enum Modes {
  APPOINTMENTS = 'APPOINTMENTS',
  PREBOOKS = 'PREBOOKS',
  WARRANT_PREBOOKS = 'WARRANT_PREBOOKS',
  WORK_ORDERS = 'WORK_ORDERS',
  PREBOOKS_CUSTOM = 'PREBOOKS_CUSTOM'
}

interface Attachment {
  public: boolean;
  fileName: string;
  _id?: string;
  createdOn?: Date;
  s3Path?: string;
  signedUrl?: string;
}
interface EventsData {
  abortedAt: Date;
  bookedAt: Date;
  cancelledAt: Date;
  completedAt: Date;
  startedAt: Date;
  pausedAt: Date;
  onSiteAt: Date;
  onRouteAt: Date;
  lastEventDate: Date;
  cancellationReason: string;
  cancellationSubReason: string;
  cancellationReasonCode: string;
  cancellationComment: string;
}

interface TriggerPoint {
  _id?: string;
  trigger: string;
  enabled: boolean;
}

interface DataFlow {
  fileName: string;
  fileContents: string;
  name: string;
  createdOn: Date;
  status: string;
}

class EventModel {
  constructor(
    public type?: string,
    public user?: string,
    public action?: string,
    public data?: string,
    public status?: string,
    public createdOn?: string | Date,
    public updatedOn?: string,
    public id?: string,
    public meta?: any,
  ) {}
}

interface Comment {
  id?: string;
  _id?: string;
  public: boolean;
  user?: UserDoc;
  comment: string;
  createdOn?: Date;
  updatedOn?: Date;
}

interface CommentCreate {
  public: boolean;
  comment: string;
}

interface CommentEdit {
  public: boolean;
  comment: string;
  id: string;
}

class QuestionModel {
  constructor(
    public id: number,
    public type: number,
    public answer: string,
    public key: string,
    public question: string,
    public deleted: string,
    public photoUrl: string,
    public modifyDate: string,
  ) {}
}

class SurveyModel {
  constructor(
    public code: string,
    public sequence: number,
    public rootId: number,
    public groupId: number,
    public groupName: string,
    public name: string,
    public id: string,
    public questions: QuestionModel[],
  ) {}
}

interface JourneyComms {
  name: string;
  active: boolean;
  comms: unknown;
}
/**
 * @deprecated Use interface instead of DTO
 */
class AppointmentModel {
  constructor(
    public id: string,
    public outwardCode: string,
    public jobType: JobType,
    public appointmentType: AppointmentType,
    public contact?: CustomerContactDetails,
    public customer?: CustomerDetails,
    public site?: SiteDetails,
    public meterDetails?: MetersData,
    public comments?: Comment[],
    public createdOn?: Date,
    public updatedOn?: Date,
    public bookingReference?: string,
    public slot?: any,
    public engineer?: any,
    public events?: EventModel[],
    public surveys?: SurveyModel[],
    public status?: AppointmentStatusEnum,
    public deleted?: boolean,
    public cancellation?: any,
    public order?: number,
    public editedBy?: UserDoc,
    public wan?: WanData,
    public wanRequestedAt?: Date,
    public journeysCommsTriggerPoints?: Array<TriggerPoint>,
    public customerConfirmation?: string,
    public eventsProcessed?: EventsData,
    public journeyComms?: JourneyComms,
    public dcc?: string,
    public failedComms?: boolean,
    public dataFlows?: DataFlow[],
    public overBooked?: boolean,
    public secondaryEngineers?: any[],
    public priority?: number,
    public s2RequestStatus?: { [key in RequestType]: any },
    public metaData?: MetaDataItem[],
    public attachments?: Attachment[],
    public travelTime?: number,
    public debt?: any,
    public charges?: any[],
    public convertedFrom?: string,
    public mode?: Modes, // TODO: Remove later
    public dataFlowsStatus?: Payload,
    public sip?: Sip,
    public nonChargeable?: boolean
  ) {}
}

/**
 * Move this to prebooks model later, as they are different entities
 * @deprecated Use newer interface
 */
interface AppointmentPrebook {
  id: string;
  outwardCode: string;
  jobType: JobType;
  appointmentType: AppointmentType;
  contact?: CustomerContactDetails;
  customer?: CustomerDetails;
  site?: SiteDetails;
  meterDetails?: MetersData;
  comments?: Comment[];
  createdOn?: Date;
  updatedOn?: Date;
  bookingReference?: string;
  slot?: any;
  engineer?: any;
  events?: EventModel[];
  surveys?: SurveyModel[];
  status?: AppointmentStatusEnum;
  deleted?: boolean;
  cancellation?: any;
  order?: number;
  editedBy?: UserDoc;
  wan?: WanData;
  wanRequestedAt?: Date;
  journeysCommsTriggerPoints?: Array<TriggerPoint>;
  customerConfirmation?: string;
  eventsProcessed?: EventsData;
  journeyComms?: JourneyComms;
  dcc?: string;
  failedComms?: boolean;
  dataFlows?: DataFlow[];
  overBooked?: boolean;
  secondaryEngineers?: any[];
  priority?: number;
  s2RequestStatus?: { [key in RequestType]: any };
  metaData?: MetaDataItem[];
  attachments?: Attachment[];
  travelTime?: number;
  debt?: any;
  charges?: any[];
  mode?: Modes;
  convertedFrom?: string; // TODO remove later
  sip?: Sip;
}

interface MetaDataItem {
  key: string;
  value: string;
  description: string;
  type: string;
}

interface Debt {
  currentDebtValue: string;
  dateOfBill: string;
  dateOfRedReminder: string;
  minimumDebtAcceptedByClient: string;
  referralForDebtCollectionActivity: string;
  preDisconnectionVisit: string;
  intendedDateForExecution: string;
  debtJourneyActiveStep: string;
  debtJourneyDate: string;
}

interface Charge {
  debtActivity: string;
  amount: number;
  user: string;
  dateChargeApplied: Date;
  visibilityToWarrant: boolean;
  _id?: string;
}

interface ContractualContact {
  premiseOwner: string;
  title: string;
  firstName: string;
  surname: string;
  contactTelephone1: string;
  contactTelephone2: string;
  contactSms: string;
  emailAddress: string;
  poNumber: string;
}

interface ContractualRequirement {
  tenantsAdvanceNotice: string;
  occupantsMedicalDependency: string;
  stepsTaken: string;
  thirdPartyPresence: string;
  thirdPartyDetails: string;
  dataSharingAgreement: string;
  premiseOwnerAwareness: string;
  occupantVulnerabilities: string;
  priorityServicesRegister: string;
  furtherVulnerabilities: string;
  vulnerabilities: string[];
}

interface ContractualEnergyDetails {
  mpan: string;
  meterSerialNumber: string;
  registeredSupplier: string;
  registeredSupplierMpid: string;
  currentTransformer: boolean;
  threePhase: boolean;
  eusr: boolean;
  ngpApproved: boolean;
}

interface ContractualSiteContact {
  title: string;
  firstName: string;
  surname: string;
  companyName: string;
  hasVulnerabilities: string;
  vulnerabilities: string[];
  contactTelephone: string;
}

interface Sip {
  contractualContact: ContractualContact;
  contractualRequirement: ContractualRequirement;
  contractualEnergyDetails: ContractualEnergyDetails;
  contractualSiteContact: ContractualSiteContact;
}

interface Appointment {
  id: string;
  outwardCode: string;
  jobType: JobType;
  appointmentType: AppointmentType;
  contact?: CustomerContactDetails;
  customer?: CustomerDetails;
  site?: SiteDetails;
  meterDetails?: MetersData;
  comments?: Comment[];
  createdOn?: Date;
  updatedOn?: Date;
  bookingReference?: string;
  slot?: any;
  engineer?: any;
  events?: EventModel[];
  surveys?: SurveyModel[];
  status?: AppointmentStatusEnum;
  deleted?: boolean;
  cancellation?: any;
  order?: boolean;
  editedBy?: UserDoc;
  wan?: WanData;
  wanRequestedAt?: Date;
  journeysCommsTriggerPoints?: Array<TriggerPoint>;
  customerConfirmation?: string;
  eventsProcessed?: EventsData;
  journeyComms?: JourneyComms;
  dcc?: string;
  bookingMethod?: string;
  lastEditedBy?: UserDoc;
  secondaryEngineers?: string[];
  priority?: number;
  s2RequestStatus?: { [key in RequestType]: any };
  metaData?: MetaDataItem[];
  debt?: Debt;
  attachments?: Attachment[];
  dataFlowsStatus?: Payload;
  sip: Sip;
  nonChargeable?: boolean;
}

enum CSPRegion {
  South = 'South',
  Central = 'Central',
  North = 'North',
}

interface WanData {
  cspRegion: CSPRegion;
  wanTechnology: string;
  coverageAvailability: boolean;
  connectivityLikelihood: string;
  auxiliaryEquipmentRequired: string;
  anticipatedCoverageDate: Date;
  additionalInformation: string;
  user: any;
  createdOn: string;
  updatedOn: string;
}

interface ReservedAppointmentPayload {
  overBooked?: boolean;
  customer?: Partial<CustomerDetails>;
  site?: SiteDetails;
  contact?: CustomerContactDetails;
  meterDetails?: MetersData;
  comments?: Comment[];
  engineer?: string;
  order?: number;
  jobType?: string;
  appointmentType?: string;
  prebook?: string;
  priority?: string;
  debt?: Debt;
  attachments?: Attachment[];
  cloned?: boolean;
  reraised?: boolean;
  reraisedFromBookingReference?: string;
  isPdv?: boolean;
  dataFlowsStatus?: Payload;
  sip?: Sip;
  nonChargeable?: boolean;
}

interface UpdateAppointmentPayload {
  customer?: CustomerDetails;
  site?: SiteDetails;
  contact?: CustomerContactDetails;
  meterDetails?: MetersData;
  comments?: Comment[];
  engineer?: string;
  order?: number;
  jobType?: string;
  appointmentType?: string;
  priority?: string;
}

interface AppointmentPayload {
  id?: string;
  outwardCode?: string;
  jobType?: string;
  appointmentType?: string;
  contact?: CustomerContactDetails;
  customer?: Partial<CustomerDetails>;
  site?: SiteDetails;
  meterDetails?: MetersData;
  day?: Date;
  patch?: string;
  supplier?: string;
  timeSlot?: string;
  comments?: Comment[];
  createdOn?: Date;
  updatedOn?: Date;
  engineer?: any;
  reserveCompletedAt?: Date;
  bookingReference?: string;
  surveys?: any[];
  order?: number;
  journeysCommsTriggerPoints?: TriggerPoint[];
  prebook?: string;
  attachments?: Attachment[];
  dcc?: string;
  priority?: string;
  debt?: Debt;
  cloned?: boolean;
  reraised?: boolean;
  reraisedFromBookingReference?: string;
  isPdv?: boolean;
  dataFlowsStatus?: Payload;
  sip?: Sip;
  nonChargeable?: boolean;
  unassignedAttachments?: Partial<Attachment>[];
}

interface DualAppointmentPayload {
  id?: string;
  outwardCode?: string;
  appointmentType?: string;
  contact?: CustomerContactDetails;
  customer?: CustomerDetails;
  site?: SiteDetails;
  meterDetails?: MetersData;
  patch?: string;
  supplier?: string;
  comments?: Comment[];
  slot1?: any;
  slot2?: any;
  jobType1: string;
  jobType2: string;
  priority?: string;
  nonChargeable?: boolean;
}

interface DirectAppointmentWithoutReservationPayload {
  id?: string;
  day?: string;
  timeSlot?: string;
  outwardCode: string;
  appointmentType: string;
  contact: CustomerContactDetails;
  customer: CustomerDetails;
  site: SiteDetails;
  meterDetails?: MetersData;
  patch: string;
  supplier: string;
  comments?: Comment[];
  slot: any;
  jobType: string;
  priority?: string;
}

interface AppointmentResume {
  supplierName;
  accountNumber: string;
  customerName;
  customerAddress;
  date;
  timeSlot;
  contact;
  telephone;
  email;
  mprn?: string;
  mpan?: string;
  gasMeterSerialNumber?: string;
  electricMeterSerialNumber?: string;
  sscCode?: string;
  profileClass?: string;
  sip: Sip;
}

interface SiteDetails {
  address1;
  address2?;
  address3?;
  town?;
  city;
  county?;
  postCode;
  dataConsent?;
  customerPassword?;
  id?;
  companyName?;
  risksHazards?;
}

enum BookingFormTypes {
  COMMENTS = 'Comments',
  CONTACT = 'Contact',
  CUSTOMER = 'Customer',
  LOOKUP = 'Lookup',
  ELECTRIC = 'Electric',
  GAS = 'Gas',
  POSTCODE = 'PostCode',
  SITE = 'Site',
  APPOINTMENT_TYPE = 'AppointmentType',
  JOB_TYPE = 'JobType',
  HH = 'HH',
  CONTRACTUAL_CONTACT = 'Contractual Contact',
  CONTRACTUAL_REQUIREMENT = 'Contractual Requirement',
  CONTRACTUAL_SITE_CONTACT = 'Contractual Site Contact',
  FLAGS = 'Flags',
}

enum AppointmentColors {
  BOOKED = 'bg-nexus-appointment-booked bg-nexus-appointment-booked-text',
  // IN_PROGRESS = 'bg-nexus-appointment-on-site bg-nexus-appointment-on-site-text',
  ON_SITE = 'bg-nexus-appointment-on-site bg-nexus-appointment-on-site-text',
  ON_ROUTE = 'bg-nexus-appointment-on-site bg-nexus-appointment-on-site-text',
  STARTED = 'bg-nexus-appointment-on-site bg-nexus-appointment-site-text',
  ABORTED = 'bg-nexus-appointment-aborted bg-nexus-appointment-aborted-text',
  CANCELLED = 'bg-nexus-appointment-aborted bg-nexus-appointment-aborted-text',
  COMPLETED = 'bg-nexus-appointment-completed bg-nexus-appointment-completed-text',
  RESCHEDULED = 'bg-nexus-appointment-started bg-nexus-appointment-started-text',
  PAUSED = 'bg-nexus-appointment-paused bg-nexus-appointment-paused-text',
}

enum AppointmentColorsV2 {
  BOOKED = 'bg-nexus-appointment-bookedv2 bg-nexus-appointment-booked-textv2',
  // IN_PROGRESS = 'bg-nexus-appointment-on-sitev2 bg-nexus-appointment-on-site-textv2',
  ON_SITE = 'bg-nexus-appointment-on-sitev2 bg-nexus-appointment-on-site-textv2',
  ON_ROUTE = 'bg-nexus-appointment-on-sitev2 bg-nexus-appointment-on-site-textv2',
  STARTED = 'bg-nexus-appointment-on-sitev2 bg-nexus-appointment-site-textv2',
  ON_SITE_BAR = 'bl b--near-black bg-nexus-appointment-on-sitev2 bg-nexus-appointment-on-site-textv2',
  ON_ROUTE_BAR = 'bl b--near-black bg-nexus-appointment-on-sitev2 bg-nexus-appointment-on-site-textv2',
  STARTED_BAR = 'bl b--near-black bg-nexus-appointment-on-sitev2 bg-nexus-appointment-site-textv2',
  ABORTED = 'bg-nexus-appointment-abortedv2 bg-nexus-appointment-aborted-textv2',
  CANCELLED = 'bg-nexus-appointment-abortedv2 bg-nexus-appointment-aborted-textv2',
  COMPLETED = 'bg-nexus-appointment-completedv2 bg-nexus-appointment-completed-textv2',
  RESCHEDULED = 'bg-nexus-appointment-scheduledv2 bg-nexus-appointment-scheduled-textv2',
  PAUSED = 'bg-nexus-appointment-pausedv2 bg-nexus-appointment-paused-textv2',
}

enum RequestType {
  ELEC_INSTALL_AND_COMMISSION_METER = 'ELEC_INSTALL_AND_COMMISSION_METER',
  GAS_INSTALL_AND_COMMISSION_METER = 'GAS_INSTALL_AND_COMMISSION_METER',
  ELEC_INSTALL_AND_LEAVE_METER = 'ELEC_INSTALL_AND_LEAVE_METER',
  GAS_INSTALL_AND_LEAVE_METER = 'GAS_INSTALL_AND_LEAVE_METER',
  COMMS_HUB_REPLACEMENT = 'COMMS_HUB_REPLACEMENT',
  REMOVE_ELEC_METER = 'REMOVE_ELEC_METER',
  REMOVE_GAS_METER = 'REMOVE_GAS_METER',
  INSTALL_TYPE_2_DEVICE = 'INSTALL_TYPE_2_DEVICE',
  TYPE_2_DEVICE_REMOVE = 'TYPE_2_DEVICE_REMOVE',
  VALIDATE_APPOINTMENT = 'VALIDATE_APPOINTMENT',
  CHFInstallSuccessSMWAN = 'CHFInstallSuccessSMWAN',
  CHFInstallNoSMWAN = 'CHFInstallNoSMWAN',
  CHFFaultReturn = 'CHFFaultReturn',
  CHFNoFaultReturn = 'CHFNoFaultReturn',
  XITTDA = 'XITTDA',
  XTTMTA = 'XTTMTA',
  TYPE_2_DEVICE_DECOMMISSION = 'TYPE_2_DEVICE_DECOMMISSION',
  TYPE_2_ADDITIONAL_DEVICE_DECOMMISSION = 'TYPE_2_ADDITIONAL_DEVICE_DECOMMISSION',
  CHECK_INVENTORY = 'CHECK_INVENTORY',
  XIPMD = 'XIPMD',
  XPPMM = 'XPPMM',
  INSTALL_TYPE_2_DEVICE_GAS = 'INSTALL_TYPE_2_DEVICE_GAS',
  INSTALL_TYPE_2_DEVICE_ELEC = 'INSTALL_TYPE_2_DEVICE_ELEC',
  REMOVE_TYPE_2_DEVICE_GAS = 'REMOVE_TYPE_2_DEVICE_GAS',
  REMOVE_TYPE_2_DEVICE_ELEC = 'REMOVE_TYPE_2_DEVICE_ELEC',
  XCPAN_GAS = 'XCPAN_GAS',
  XCPAN_ELEC = 'XCPAN_ELEC',
  XCANC = 'XCANC',
  AUXILIARY_EQUIPMENTS = 'AUXILIARY_EQUIPMENTS',
  AUXILIARY_EQUIPMENT_CONFIGURATION = 'AUXILIARY_EQUIPMENT_CONFIGURATION',
}
interface EngineersPerformance {
  averageBookings: number;
  averageCompletedInstalls: number;
}

interface SwapOrder {
  from: number;
  to: number;
  page: number;
}

export {
  AppointmentModel,
  AppointmentResume,
  SiteDetails,
  AppointmentPayload,
  ReservedAppointmentPayload,
  AppointmentStatusPretty,
  EventModel,
  AppointmentStatusEnum,
  EventTypesEnum,
  SurveyModel,
  QuestionModel,
  AppointmentColors,
  AppointmentColorsV2,
  EngineersPerformance,
  SwapOrder,
  WanData,
  Comment,
  CommentCreate,
  CommentEdit,
  UpdateAppointmentPayload,
  TriggerPoint,
  BookingFormTypes,
  DualAppointmentPayload,
  Appointment,
  DataFlow,
  DirectAppointmentWithoutReservationPayload,
  RequestType,
  MetaDataItem,
  Attachment,
  Modes,
  Debt,
  Charge,
  AppointmentPrebook,
  debtJourneySteps,
  Sip,
  ContractualContact,
  ContractualRequirement,
  ContractualEnergyDetails,
  ContractualSiteContact
};
