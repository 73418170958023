<section *ngIf="items && items.length > 0" class="flex pv2 flex-wrap">
  <article class="w-20 ph2 pv1" *ngFor="let item of items" (click)="selectItem(item.id)">
    <div class="button w-100 pa1 dim pointer flex items-center" [ngClass]="getItemClass(item)">
      <div class="f6 fw6 nexus-dark-blue">{{ item.name }}</div>
    </div>
  </article>
</section>
<!-- <app-pagination class="pa2" *ngIf="pagination && roles.length > 0 && pagination.totalPages > 1"
  [paginationState]="pagination" (goToPage)="onGoToPage($event)"> </app-pagination> -->

<section *ngIf="!items">
  <article class="w-20 ph2 pv1">
    <div class="button w-100 pa1 dim pointer flex items-center">
      <div class="f6 fw6 nexus-dark-blue">&nbsp;</div>
    </div>
  </article>
</section>
