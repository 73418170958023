import { Component, Input, Output, EventEmitter, OnDestroy, OnInit } from '@angular/core';

import { Subscription } from 'rxjs';
import { UntypedFormGroup, UntypedFormControl, Validators, AbstractControl } from '@angular/forms';
import { debounceTime, distinctUntilChanged, filter } from 'rxjs/operators';

import { FormsDisablerService } from '@nexus/services/forms-disabler.service';
import { ServiceLocator } from '@nexus/services/service-locator';

@Component({
  selector: 'app-form-base',
  templateUrl: './form-base.component.html',
  styleUrls: ['./form-base.component.scss'],
})
export class FormBaseComponent implements OnDestroy, OnInit {
  @Output() formFilled: EventEmitter<any> = new EventEmitter();
  @Input() debounceTime: number;
  @Input() formDisabled: boolean = false;

  // DM
  public formSubscription: any;
  protected declare formsDisablerService: FormsDisablerService;
  protected subscriptions = new Subscription();
  public declare form: UntypedFormGroup;
  public errorMessages = {
    default: 'Please verify this field.',
  };

  constructor() {
    this.formsDisablerService = ServiceLocator.injector.get(FormsDisablerService);
    const allFormsDisabled$ = this.formsDisablerService.allFormsDisabled$.subscribe((disable: boolean) => {
      if (disable) {
        this.disableAllFormControls();
      }
    });
    this.subscriptions.add(allFormsDisabled$);
  }

  ngOnInit() {
    if (this.formDisabled) {
      this.disableAllFormControls();
    }
  }

  disableAllFormControls() {
    if (this.form) {
      const allControls = this.form.controls as any;
      for (const formControlKey in allControls) {
        if (formControlKey) {
          const formControl = allControls[formControlKey];
          formControl.disable();
        }
      }
    }
  }

  enableAllFormControls() {
    if (this.form) {
      const allControls = this.form.controls as any;
      for (const formControlKey in allControls) {
        if (formControlKey) {
          const formControl = allControls[formControlKey];
          formControl.enable();
        }
      }
    }
  }

  getErrorMessage(control: UntypedFormControl | string): string {
    let controlName: UntypedFormControl;
    if(typeof control === 'string'){
      controlName = this.form.get(control) as UntypedFormControl;
    } else {
      controlName = control;
    }
    const controlErrorMessages = controlName.errors;
    const allErrorMessages = Object.keys(controlErrorMessages);
    let firstErrorMessage = allErrorMessages.length > 0 ? controlName.errors[allErrorMessages[0]] : this.errorMessages.default;
    if (typeof firstErrorMessage !== 'string') {
      firstErrorMessage = this.errorMessages.default;
    }
    return controlName.invalid && (controlName.dirty || controlName.touched) ? firstErrorMessage : '';
  }
  getErrorClass(control: UntypedFormControl | string): string {
    let controlName: UntypedFormControl;
    if(typeof control === 'string'){
      controlName = this.form.get(control) as UntypedFormControl;
    } else {
      controlName = control;
    }
    return controlName && controlName.invalid && (controlName.dirty || controlName.touched) ? 'b--dark-red dark-red' : '';
  }
  hasError(control: UntypedFormControl | string): boolean {
    let controlName: UntypedFormControl;
    if(typeof control === 'string'){
      controlName = this.form.get(control) as UntypedFormControl;
    } else {
      controlName = control;
    }
    return controlName.invalid && (controlName.dirty || controlName.touched);
  }
  onFormFocusOut() {
    Object.keys(this.form.controls).forEach((field) => {
      const control = this.form.get(field);
      control.markAsTouched({ onlySelf: true });
    });
  }
  subscribeToForm() {
    if (this.formDisabled) {
      return;
    }
    this.formSubscription = this.form.valueChanges
      .pipe(
        debounceTime(this.debounceTime || 500),
        distinctUntilChanged(),
        filter((data) => {
          return this.form.valid;
        }),
      )
      .subscribe((formData) => {
        if (this.formDisabled) {
          return;
        }
        this.formFilled.emit(formData);
      });
  }

  isFormControlRequired(controlName: string): boolean {
    if(!this.form){
      return false;
    }
    const control = this.form.get(controlName);
    if (!control) {
      return false;
    }
    return control.hasValidator(Validators.required);
  }

  unSubscribeToForm(): void {
    if (this.formSubscription) {
      this.formSubscription.unsubscribe();
    }
  }

  ngOnDestroy() {
    this.subscriptions.unsubscribe();
  }
}
