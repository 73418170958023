<div class="tc mw8 center" *ngIf="paginationState">
  <div class="dib overflow-hidden">
    <nav class="cf" data-name="pagination-numbers-bordered">
      <a
        class="fl dib link black bn f6 f6-ns b pagination-rounded"
        [ngClass]="{ disabled: !paginationState.hasPrevPage }"
        href="javascript:;"
        (click)="onGoToPage(1)"
      >
        &#8676;
      </a>
      <a
        class="fl dib link black bn f6 f6-ns b pagination-rounded"
        [ngClass]="{ disabled: !paginationState.hasPrevPage }"
        href="javascript:;"
        (click)="onGoToPage(paginationState.page - 1)"
      >
        &#8592;
      </a>
      <a
        class="fr dib link black bn f6 f6-ns b pagination-rounded"
        [ngClass]="{ disabled: !paginationState.hasNextPage }"
        href="javascript:;"
        (click)="onGoToPage(paginationState.totalPages)"
      >
        &#8677;
      </a>
      <a
        class="fr dib link black bn f6 f6-ns b pagination-rounded"
        [ngClass]="{ disabled: !paginationState.hasNextPage }"
        href="javascript:;"
        (click)="onGoToPage(paginationState.page + 1)"
      >
        &#8594;
      </a>
      <div class="overflow-hidden center dt tc">
        <ng-container *ngFor="let page of totalPagesArray; let i = index">
          <a
            *ngIf="page !== -1"
            class="dib link f6 f6-ns b pagination-rounded"
            [ngClass]="currentPageClass(page + 1)"
            href="javascript:;"
            title="page+1"
            (click)="onGoToPage(page + 1)"
            >{{ page + 1 }}</a
          >
          <a *ngIf="page === -1" class="dib link f6 f6-ns b pagination-rounded" href="javascript:;" title="page+1">...</a>
        </ng-container>
      </div>
    </nav>
  </div>
</div>
