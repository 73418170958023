import { Directive, ElementRef, OnInit } from '@angular/core';

@Directive({
  selector: '[appAcDisabler]',
})
/**
 * Alterates autocomplete="off" atribute on chrome because it's ignoring it in case of credentials, address or credit card data type.
 * https://stackoverflow.com/questions/48084770/disable-autofill-autocomplete-with-angular-form-and-chrome?rq=1
 */
export class AutocompleteDirective implements OnInit {
  private chrome = navigator.userAgent.indexOf('Chrome') > -1;
  constructor(private el: ElementRef) {}
  generateRandom() {
    return Math.random().toString(36).slice(2);
  }

  ngOnInit() {
    if (this.chrome) {
      if (this.el.nativeElement.getAttribute('autocomplete') === 'off') {
        setTimeout(() => {
          this.el.nativeElement.setAttribute('autocomplete', this.generateRandom());
        });
      }
    }
  }
}
