import { Directive, OnDestroy, Input, EventEmitter, HostListener, Renderer2, ElementRef, OnInit } from '@angular/core';

@Directive({
  selector: '[appAllowWordsOnly]',
})
export class AllowWordsOnlyDirective {
  @HostListener('keypress', ['$event'])
  keyPressEngineer(event: any) {
    const inp = String.fromCharCode(event.keyCode);
    if (/[a-zA-Z ]/.test(inp)) {
      return true;
    } else {
      return false;
    }
  }
  constructor() {}
}
